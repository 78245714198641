import React from "react";

const TwitterLike = (props) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.9999 14.2285H7.9894C6.05215 14.1925 0.462402 9.14197 0.462402 4.35847C0.462402 2.06047 2.35615 0.0429688 4.51465 0.0429688C6.23215 0.0429688 7.38715 1.22797 7.99915 2.09047C8.60965 1.22947 9.76465 0.0429688 11.4829 0.0429688C13.6429 0.0429688 15.5359 2.06047 15.5359 4.35922C15.5359 9.14122 9.9454 14.1917 8.00815 14.227H7.9999V14.2285Z"
        fill="#F4245E"
      />
    </svg>
  );
};

export default TwitterLike;
