import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { AuthInput } from "..";

const InputWithTitle = ({ textCount, label, ...props }) => {
  return (
    <Box mt="5">
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Text fontWeight={"500"} fontSize={20} color={"gray"}>
          {label}
        </Text>
        <Text fontWeight={"500"} fontSize="14" color={"lightGray"}>
          {textCount}/200
        </Text>
      </Box>
      <AuthInput {...props} />
    </Box>
  );
};

export default InputWithTitle;
