import React from "react";
import { Box, Text, Image, Icon } from "@chakra-ui/react";
import images from "../../constants/images";
import {
  TwitterReply,
  TwitterLike,
  TwitterRetweet,
  TwitterShare,
} from "../icons";

const SocialTwitterCard = ({ text,resImg,dashInfo }) => {
  return (
    <Box backgroundColor={"white"} borderRadius={10}>
      <Box display={"flex"} p="3">
        <Box flex={1}>
          <Image src={images.facebookAvatar} w="10" h="10" borderRadius={100} />
        </Box>
        <Box flex={8}>
          <Text
            display={"flex"}
            fontWeight={"600"}
            fontSize="16"
            color={"#0F1419"}
            gap="1"
          >
            {dashInfo?.name} {" "}
            <Text fontWeight={"500"} fontSize="16" color="#5B7083">
              @Orbina
            </Text>
          </Text>
          <Text fontWeight={"400"} fontSize="16" color={"#0F1419"}>
            {text}
          </Text>
          <Image
            src={resImg ? resImg : images.cat}
            borderRadius={10}
            display="flex"
            alignSelf="flex-end"
            mt="1"
            h="300"
            w={"100%"}
            objectFit="cover"
          />
          <Box display={"flex"} gap="14" my="3">
            <BottomIcon icon={TwitterReply} text="61" />
            <BottomIcon icon={TwitterRetweet} text="12" />
            <BottomIcon icon={TwitterLike} text="6.2K" islike={true} />
            <BottomIcon icon={TwitterShare} text="61" />
          </Box>
          <Text color="#1DA1F2" fontWeight={"500"} fontSize="12">
            Show This Thread
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default SocialTwitterCard;

const BottomIcon = ({ icon, text, islike }) => {
  return (
    <Box display={"flex"} gap="2" alignItems={"center"}>
      <Icon as={icon} />
      <Text color={islike ? "#F4245E" : "#5B7083"}>{text}</Text>
    </Box>
  );
};
