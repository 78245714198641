import React from "react";

const FacebookComment = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 0C18.1641 0 18.75 0.273438 19.2578 0.742188C19.7266 1.25 20 1.83594 20 2.5V13.75C20 14.4531 19.7266 15.0391 19.2578 15.5078C18.75 16.0156 18.1641 16.25 17.5 16.25H11.875L6.99219 19.9219C6.91406 20 6.79688 20 6.67969 20C6.5625 20 6.44531 19.9609 6.36719 19.8828C6.28906 19.8047 6.25 19.6875 6.25 19.5312V16.25H2.5C1.79688 16.25 1.21094 16.0156 0.742188 15.5078C0.234375 15.0391 0 14.4531 0 13.75V2.5C0 1.83594 0.234375 1.25 0.742188 0.742188C1.21094 0.273438 1.79688 0 2.5 0H17.5ZM18.75 13.75V2.5C18.75 2.1875 18.5938 1.875 18.3594 1.64062C18.125 1.40625 17.8125 1.25 17.5 1.25H2.5C2.14844 1.25 1.83594 1.40625 1.60156 1.64062C1.36719 1.875 1.25 2.1875 1.25 2.5V13.75C1.25 14.1016 1.36719 14.4141 1.60156 14.6484C1.83594 14.8828 2.14844 15 2.5 15H7.5V17.9688L11.4453 15H17.5C17.8125 15 18.125 14.8828 18.3594 14.6484C18.5938 14.4141 18.75 14.1016 18.75 13.75Z"
        fill="#606770"
      />
    </svg>
  );
};

export default FacebookComment;
