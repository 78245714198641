import React from "react";

const TwitterReply = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.53452 0.681328L6.42352 0.673828H6.42202C3.14152 0.673828 0.572021 3.24408 0.572021 6.52533C0.572021 9.59883 2.96152 11.9298 6.17077 12.0528V14.9238C6.17077 15.0048 6.20377 15.1383 6.26077 15.2261C6.36727 15.3948 6.54877 15.4863 6.73477 15.4863C6.83827 15.4863 6.94252 15.4578 7.03627 15.3978C7.23427 15.2718 11.891 12.2928 13.1023 11.2683C14.5288 10.0608 15.3823 8.29083 15.3845 6.53433V6.52158C15.38 3.24633 12.812 0.681328 9.53452 0.680578V0.681328ZM12.3748 10.4103C11.5243 11.1303 8.72827 12.9641 7.29577 13.8926V11.5023C7.29577 11.1918 7.04452 10.9398 6.73327 10.9398H6.43627C3.69127 10.9398 1.69777 9.08283 1.69777 6.52533C1.69777 3.87483 3.77377 1.79883 6.42277 1.79883L9.53302 1.80633H9.53452C12.1835 1.80633 14.2595 3.88083 14.261 6.52833C14.2588 7.96083 13.5545 9.41133 12.3755 10.4103H12.3748Z"
        fill="#5B7083"
      />
    </svg>
  );
};

export default TwitterReply;
