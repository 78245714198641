import React from "react";

const TwitterRetweet = (props) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.8274 9.7517C17.6084 9.53195 17.2522 9.53195 17.0324 9.7517L15.3674 11.4167V3.7367C15.3674 2.1857 14.1052 0.924203 12.5549 0.924203H8.16742C7.85692 0.924203 7.60492 1.1762 7.60492 1.4867C7.60492 1.7972 7.85692 2.0492 8.16742 2.0492H12.5549C13.4849 2.0492 14.2424 2.8067 14.2424 3.7367V11.4167L12.5774 9.7517C12.3577 9.53195 12.0014 9.53195 11.7824 9.7517C11.5634 9.97145 11.5619 10.3277 11.7824 10.5467L14.4074 13.1717C14.5162 13.282 14.6602 13.3367 14.8049 13.3367C14.9497 13.3367 15.0922 13.2827 15.2024 13.1717L17.8274 10.5467C18.0479 10.3277 18.0479 9.97145 17.8274 9.7517ZM9.83242 12.2117H5.44492C4.51492 12.2117 3.75742 11.4542 3.75742 10.5242V2.8442L5.42242 4.5092C5.53342 4.61945 5.67742 4.6742 5.82142 4.6742C5.96542 4.6742 6.10942 4.61945 6.21892 4.5092C6.43867 4.28945 6.43867 3.9332 6.21892 3.7142L3.59392 1.0892C3.37417 0.868703 3.01792 0.868703 2.79892 1.0892L0.17392 3.7142C-0.0465801 3.9332 -0.0465801 4.28945 0.17392 4.5092C0.39442 4.72895 0.74917 4.72895 0.96892 4.5092L2.63392 2.8442V10.5242C2.63392 12.0752 3.89617 13.3367 5.44642 13.3367H9.83392C10.1444 13.3367 10.3964 13.0847 10.3964 12.7742C10.3964 12.4637 10.1437 12.2117 9.83392 12.2117H9.83242Z"
        fill="#5B7083"
      />
    </svg>
  );
};

export default TwitterRetweet;
