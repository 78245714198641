import React from "react";
import { Box, Text, Image, Icon } from "@chakra-ui/react";
import images from "../../constants/images";
import { InstaLike, InstaComment, InstaSend } from "../icons";

const SocialInstagramCard = ({ text, resImg, dashInfo }) => {
  return (
    <Box backgroundColor={"white"} borderRadius={10}>
      <Box display={"flex"} alignItems="center" p="2">
        <Box backgroundColor={"gray"} w="10" h="10" borderRadius={100} />
        <Box ml="3">
          <Text fontWeight={"600"} fontSize="16" color={"orbBlack"}>
            {dashInfo?.name}
          </Text>
          <Text fontWeight={"500"} fontSize="12" color={"black"}>
            Sponsored
          </Text>
        </Box>
      </Box>
      <Image
        src={resImg ? resImg : images.cat}
        h="300"
        w={"100%"}
        objectFit="cover"
      />
      <Box p="2" borderBottomWidth={1} borderColor="#F2F2F2">
        <Text fontWeight={"700"} fontSize="12" color="black">
          Shop Now
        </Text>
      </Box>
      <Box p="2" display={"flex"} gap="4">
        <Icon as={InstaLike} />
        <Icon as={InstaComment} />
        <Icon as={InstaSend} />
      </Box>
      <Box ml="2" mb="4">
        <Text fontWeight={"700"} fontSize="16" color="orbBlack">
          {dashInfo?.name}
        </Text>
        <Text fontWeight={"400"} fontSize="16" color="#7D8398">
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export default SocialInstagramCard;
