import React from "react";
import { Box, Text, Button,useDisclosure } from "@chakra-ui/react";
import {
  FeedBackModal,
  FeedbackButton
} from "../../components";

const UpdatePlanCard = ({modalType,currentData}) => {

  const feedBackModal = useDisclosure();


  return (
    <Box
      minH={"10vh"}
      w="100%"
      display={"flex"}
      alignItems="center"
      justifyContent={"space-between"}
      borderRadius={10}
      backgroundColor="rgba(255, 255, 255, 0.1)"
      px={5}
      mt="4"
    >
      <Text
        fontWeight={"500"}
        fontSize={{ base: "10", md: "16" }}
        color={"white"}
      >
       We need your feedback to improve Orbina, left us your feedbacks.
      </Text>
      <FeedbackButton></FeedbackButton>
      <Button 
        className="feedback-button"
        borderRadius={"10"} 
        color="white" 
        variant="outline" 
        onClick={() => feedBackModal.onOpen()}  
        bgGradient={
          "linear-gradient(90deg, #042b66 0.01%, #2fafe7 99.99%)"
        }
      >
        <Text
          color={"white"}
          fontWeight={"500"}
          fontSize={{ base: "10", md: "16" }}
        >
          Leave Your Feedback
        </Text>
      </Button>

      <FeedBackModal
        onClose={feedBackModal.onClose}
        isOpen={feedBackModal.isOpen}
        modalType = {modalType}
        currentData = {currentData}
      />

    </Box>
  );
};

export default UpdatePlanCard;
