import React, { useEffect } from "react";
import {
    Text,
    Box,
    Icon,
    Image,
    Divider,
    Textarea,
    useDisclosure,
    Badge,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "@chakra-ui/react";
import {
    DefaultButton,
} from "../";
import { useFormik } from "formik";
import { FaStar } from 'react-icons/fa';
import  {postFeedback } from "../../api/feedback";

const FeedBackModal = ({ onClose = () => { }, isOpen = false, modalType, currentData }) => {

    const formik = useFormik({
        initialValues: {
            rating: {
                userRate: 0,
                productRate: 0,
                orbinaRate: 0
            },
            description: ''
        },

        onSubmit: async (values) => {
            // Form submit handler

            var obj = {
                type : modalType,
                rating1 : values?.rating.orbinaRate,
                rating2 : values?.rating.productRate,
                rating3 :  values?.rating.userRate,
                comment :  values?.description,
                imageId : currentData?._id || currentData?.imageId,
                textId : currentData?.textId,
                text2Id : currentData?.text2Id,
            }
            postFeedback(obj)
            onClose();
        }

    });

    useEffect(() => {
        if (isOpen) {
            formik.resetForm();
        }
    }, [isOpen]);

    const handleRating = (ratingType, rating) => {
        formik.setFieldValue(`rating.${ratingType}`, rating);
    };


    return (
        <Modal isCentered isOpen={isOpen}>
            <ModalOverlay bg={"rgb(162, 162, 162, 0.79)"} />
            <ModalContent boxShadow="none">
                <ModalHeader>Feedback</ModalHeader>
                <ModalBody>
                    <Box maxWidth="400px">
                        <Box mb={4} display="flex" alignItems="center">
                            <Text color="black" mr={2}>Rate User Experience :</Text>

                            {[1, 2, 3, 4, 5].map((rating) => (
                                <FaStar
                                    key={rating}
                                    size={24}
                                    color={rating <= formik.values.rating.userRate ? '#F9CE00' : 'gray'}
                                    cursor="pointer"
                                    onClick={() => handleRating("userRate", rating)}
                                    style={{ marginRight: '4px' }}
                                />
                            ))}
                        </Box>
                        <Box mb={4} display="flex" alignItems="center">
                            <Text color="black" mr={2}> Rate Interface Design :</Text>

                            {[1, 2, 3, 4, 5].map((rating) => (
                                <FaStar
                                    key={rating}
                                    size={24}
                                    color={rating <= formik.values.rating.productRate ? '#F9CE00' : 'gray'}
                                    cursor="pointer"
                                    onClick={() => handleRating("productRate", rating)}
                                    style={{ marginRight: '4px' }}
                                />
                            ))}
                        </Box>
                        <Box mb={4} display="flex" alignItems="center">
                            <Text color="black" mr={2}>Rate General Performance :</Text>

                            {[1, 2, 3, 4, 5].map((rating) => (
                                <FaStar
                                    key={rating}
                                    size={24}
                                    color={rating <= formik.values.rating.orbinaRate ? '#F9CE00' : 'gray'}
                                    cursor="pointer"
                                    onClick={() => handleRating("orbinaRate", rating)}
                                    style={{ marginRight: '4px' }}
                                />
                            ))}
                        </Box>
                        <form onSubmit={formik.handleSubmit}>
                            <FormControl isInvalid={formik.errors.description} mb={4}>
                                <FormLabel htmlFor="description" color="black">Comment (Optional)</FormLabel>
                                <Textarea
                                    id="description"
                                    placeholder=".."
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    _placeholder={{ opacity: 0.6 }}
                                    backgroundColor="white"
                                    borderRadius={10}
                                    mb="2"
                                    mt="1"
                                    minH={20}
                                    maxH={null}
                                />
                                <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                            </FormControl>

                            {/* <Button type="submit" colorScheme="teal" isLoading={formik.isSubmitting}>
                                Submit
                            </Button> */}

                            <DefaultButton
                                text={"Submit"}
                                //rightIcon={<Icon as={Pencil} />}
                                as="button"
                                type="submit"
                                isLoading={formik.isSubmitting}
                                color="white"
                                fontWeight="bold"
                                borderRadius={10}
                                mb="1"
                                borderWidth={1}
                                borderColor={"white"}
                                backgroundColor={"transparent"}
                                bgGradient={"linear-gradient(90deg, #042b66 0.01%, #2fafe7 99.99%)"}
                                boxShadow={"xl"}
                                width="150px"
                                alignSelf="center"
                            // onClick={() => CreateText()}
                            />

                        </form>
                    </Box>
                </ModalBody>
                {/* <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter> */}
            </ModalContent>
        </Modal>
    );
};

export default FeedBackModal;
