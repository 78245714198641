/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, Text, Icon, Button, Divider, useBoolean, Flex, Select, Progress, useDisclosure } from "@chakra-ui/react";
import { DashBoardLayout, DashboardCard, ComingSoon } from "../../components";
import {
  Photo,
  Difference,
  Instagram,
  InstagramWhite,
  Medium,
  MediumWhite,
  Twitter,
  TwitterWhite,
  Linkedn,
  LinkednWhite,
  Reddit,
  RedditColor,
  FaceBook,
  FaceBookColor,
  BookMark,
  Description,
} from "../../components/icons";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { dashboradInfo, userUsage } from "../../api/user";

import { Stage, Layer, Image, Line } from 'react-konva';
import useImage from "use-image";

const DashboardHome = () => {
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [lines, setLines] = useState([]);
  const [tool, setTool] = useState('pen');
  const isDrawing = React.useRef(false);
  const upgradeModal = useDisclosure();

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const img = new window.Image();
      img.src = reader.result;
      img.onload = () => {
        setImage(img);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  };

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool: tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const [hoverIns, setHoverIns] = useBoolean();
  const [hoverTwt, setHoverTwt] = useBoolean();
  const [hoverLnk, setHoverLnk] = useBoolean();
  const [hoverFbk, setHoverFbk] = useBoolean();
  const [hoverMdm, setHoverMdm] = useBoolean();
  const [hoverRdt, setHoverRdt] = useBoolean();

  const [dashInfo, setDashInfo] = useState({})
  const [userUsageBar, setUserUsageBar] = useState({})
  useEffect(() => {
    document.title = 'Dashboard | Orbina AI ';

    const getData = async () => {
      const response = await dashboradInfo();
      const response2 = await userUsage();
      setUserUsageBar(response2?.data)
      setDashInfo(response?.data)
    }

    getData();

  }, [])

  return (
    <DashBoardLayout title="Dashboard">
      <Box mb={2} mt={2} backgroundColor="blur" borderRadius={10} p="2">

        {/* <div>
          <input type="file" onChange={handleUpload} />
          <button onClick={() => setTool('pen')}>Pen</button>
          <button onClick={() => setTool('eraser')}>Eraser</button>
          <Stage
            width={window.innerWidth}
            height={window.innerHeight}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
          >
            <Layer>
              {image && (
                <Image
                  image={image}
                  x={20}
                  y={20}
                  width={500}
                  height={500}
                />
              )}
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke={line.tool === 'eraser' ? '#FFFFFF' : '#df4b26'}
                  strokeWidth={25}
                  tension={0.5}
                  lineCap="round"
                  globalCompositeOperation={
                    line.tool === 'eraser' ? 'destination-out' : 'source-over'
                  }
                />
              ))}
            </Layer>
          </Stage>
        </div> */}

        <Text fontWeight={"600"} fontSize="28" color={"white"} ml="4">
          Hello, {dashInfo?.name}!
        </Text>
        <Text fontWeight={"400"} fontSize="18" color={"white"} ml="4">
          {dashInfo?.date}
        </Text>
      </Box>
      <Box
        display={"flex"}
        gap="5"
        flexDirection={{ base: "column", md: "row" }}
        mt={2}
      >
        <Box w={{ base: "100%", md: "55%" }}
          backgroundColor="blur"
          borderRadius={10}
          p="3"
        >
          <Text color={"white"} fontWeight={"600"} fontSize="24">
            Orbina Wallet
          </Text>
          <Box
            display="grid"
            gridGap="4"
            mt={2}
            gridTemplateColumns={{
              base: "1fr",
              md: "1fr",
              lg: "1fr 1fr",
            }}

          >
            <DashboardCard
              icon={<Icon as={Photo} boxSize={14} color="gray3" />}
              title="Image Wallet"
              text={
                "Create original images from scratch with a few descriptive words."
              }
              onClick={() => navigate("/saved/image")}
              isHome={true}
              count={dashInfo?.countImage}
            />
            <DashboardCard
              icon={<Icon as={Difference} boxSize={14} color="gray3" />}
              title="Text Wallet"
              text={
                "Describe your topic to our AI to start generating creative article ideas and titles."
              }
              onClick={() => navigate("/saved/text")}
              isHome={true}
              count={dashInfo?.countText}
            />
          </Box>
        </Box>
        <Box
          w={{ base: "100%", md: "45%" }}
          display="grid"
          gridGap="2"

          gridTemplateColumns={{
            base: "1fr",
            md: "1fr",
            lg: "1fr",
          }}
        >
          <Box
            backgroundColor={"blur"}
            borderRadius={10}
            p="3"
            display={"flex"}
            flexDirection="column"
            justifyContent={"space-around"}
            pl="5"
          >
            <Text fontWeight={"500"} color={"white"}>
              To continue generating content subscribe to a plan.
            </Text>
            <Button
              w={{ base: "50%", md: "50%", lg: "30%" }}
              borderRadius={"10"}
              bgGradient={"linear-gradient(90deg, #042b66 0.01%, #2fafe7 99.99%)"}
              onClick={() => upgradeModal.onOpen()}
            >
              <Text
                color={"white"}
                fontWeight={"500"}
                fontSize={{ base: "10", md: "16" }}
              >
                Upgrade Now
              </Text>
            </Button>
          </Box>
          <Box
            backgroundColor={"blur"}
            display="flex"
            flexDirection={"column"}
            justifyContent={"space-around"}
            pl="5"
            borderRadius={10}
            p="3"
          >
            <Text pl="2" fontWeight={"600"} color={"white"} fontSize="28">
              Share Your Content Via
              <Divider color={"#E0DFF0"} h={"0.5"} orientation="horizontal" />
            </Text>
            <Box
              display={"flex"}
              justifyContent="space-around"
              alignItems="center"
            >
              <Icon
                as={hoverIns ? Instagram : InstagramWhite}
                onMouseEnter={setHoverIns.on}
                onMouseLeave={setHoverIns.off}
              />
              <Divider color={"#E0DFF0"} h="10" orientation="vertical" />
              <Icon
                as={hoverFbk ? FaceBookColor : FaceBook}
                onMouseEnter={setHoverFbk.on}
                onMouseLeave={setHoverFbk.off}
              />
              <Divider color={"#E0DFF0"} h="10" orientation="vertical" />
              <Icon
                as={hoverLnk ? Linkedn : LinkednWhite}
                onMouseEnter={setHoverLnk.on}
                onMouseLeave={setHoverLnk.off}
              />
              <Divider color={"#E0DFF0"} h="10" orientation="vertical" />
              <Icon
                as={hoverTwt ? Twitter : TwitterWhite}
                onMouseEnter={setHoverTwt.on}
                onMouseLeave={setHoverTwt.off}
              />
              <Divider color={"#E0DFF0"} h="10" orientation="vertical" />
              <Icon
                as={hoverMdm ? Medium : MediumWhite}
                onMouseEnter={setHoverMdm.on}
                onMouseLeave={setHoverMdm.off}
              />
              <Divider color={"#E0DFF0"} h="10" orientation="vertical" />
              <Icon
                as={hoverRdt ? RedditColor : Reddit}
                onMouseEnter={setHoverRdt.on}
                onMouseLeave={setHoverRdt.off}
              />
            </Box>
          </Box>
        </Box>
      </Box>


      <Box
        display={"flex"}
        flexDirection="column"
        gap={"3"}
        w={{ base: "100%", md: "100%", lg: "100%" }}
        minH={"250"}
        mt="5"
        p={"3"}
        borderRadius={10}
        backgroundColor={"blur"}
      >
        <Text color={"white"} fontWeight={"600"} fontSize="24">
          Quick Actions
        </Text>
        <Box
          display="grid"
          gridGap="3"
          mt={2}
          gridTemplateColumns={{
            base: "1fr",
            md: "1fr",
            lg: "1fr 1fr",
          }}
        >
          <SocialCard
            icon={Photo}
            title="Image Generation"
            text="Create original images from scratch with a few descriptive words."
            onClick={() => navigate(routes.imageGenerationDashBoard.path)}
          />
          <SocialCard
            icon={Difference}
            title="Content Creation"
            text="Describe your topic to our AI to start generating creative article ideas and titles."
            onClick={() => navigate(routes.textArticleDashBoard.path)}
          />
          <SocialCard
            icon={Description}
            title="Templates"
            text="Use powerful aI templaters to brainstorm, Rewrite & more."
            onClick={() => navigate(routes.templatesDashBoard.path)}
          />
          <SocialCard
            icon={BookMark}
            title="Saved"
            text="Use powerful aI templaters to brainstorm, Rewrite & more."
            onClick={() => navigate(routes.saved.path)}
          />
        </Box>
        {/* <Box display={"flex"} flexDirection={{base: "column", md: "row"}} gap="3" minH={{ base: "35%", md: "35%" }}>
         
        </Box>
        <Box display={"flex"} flexDirection={{base: "column", md: "row"}} gap="3" minH={{ base: "35%", md: "35%" }}>
         
        </Box> */}
      </Box>

      <Box
        display={"flex"}
        flexDirection="column"
        gap={"3"}
        w={{ base: "100%", md: "100%", lg: "100%" }}
        minH={"250"}
        mt="5"
        p={"3"}
        borderRadius={10}
        backgroundColor={"blur"}
      >
        <Text color={"white"} fontWeight={"600"} fontSize="24">
          Your Remaining Trial Credit
        </Text>
        <Text color={"white"} fontWeight={"400"} fontSize="16" mt={-3} mb={1}>
          (All credits reset at 00:00 am.)
        </Text>

        <div style={{ width: '100%' }}>

          <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', padding: '20px' }}>

            <Flex alignItems="flex-end" mb={3}>
              <Box w="80%" position="relative" mb={3}>
                <Text fontWeight="bold" mb={2}>Image Generation Credit Limit For Today </Text>
                <Progress value={parseInt((userUsageBar?.imageCount / userUsageBar?.imageMax) * 100)} colorScheme='#042b66;' bg="rgba(0,0,0,0.1)" borderRadius="8px" hasStripe isAnimated />
              </Box>
              <Box w="20%" display="flex" justifyContent="center" alignItems="flex-end">
                <Text fontWeight="bold" mb={1} alignSelf="flex-end">Usage: {(userUsageBar?.imageCount + " / " + userUsageBar?.imageMax)}</Text>
              </Box>
            </Flex>

            <Flex alignItems="flex-end" mb={3}>
              <Box w="80%" position="relative" mb={3}>
                <Text fontWeight="bold" mb={2}>Text & Article Credit Limit For Today </Text>
                <Progress value={parseInt((userUsageBar?.textCount / userUsageBar?.textMax) * 100)} colorScheme='#042b66;' bg="rgba(0,0,0,0.1)" borderRadius="8px" hasStripe isAnimated />
              </Box>
              <Box w="20%" display="flex" justifyContent="center" alignItems="flex-end">
                <Text fontWeight="bold" mb={2} alignSelf="flex-end">Usage: {(userUsageBar?.textCount + " / " + userUsageBar?.textMax)} </Text>
              </Box>
            </Flex>

            <Flex alignItems="flex-end" mb={3}>
              <Box w="80%" position="relative" mb={3}>
                <Text fontWeight="bold" mb={3}>Chatbot Credit Limit For Today </Text>
                <Progress value={parseInt((userUsageBar?.chatCount / userUsageBar?.chatMax) * 100)} colorScheme='#042b66;' bg="rgba(0,0,0,0.1)" borderRadius="8px" hasStripe isAnimated />
              </Box>
              <Box w="20%" display="flex" justifyContent="center" alignItems="flex-end">
                <Text fontWeight="bold" mb={2} alignSelf="flex-end">Usage:  {(userUsageBar?.chatCount + " / " + userUsageBar?.chatMax)}</Text>
              </Box>
            </Flex>

            <Flex alignItems="flex-end">
              <Box w="80%" position="relative">
                <Text fontWeight="bold" mb={2}>Customized Generation Credit Limit For Today </Text>
                <Progress value={parseInt((userUsageBar?.customCount / userUsageBar?.customMax) * 100)} colorScheme='#042b66;' bg="rgba(0,0,0,0.1)" borderRadius="8px" hasStripe isAnimated />
              </Box>
              <Box w="20%" display="flex" justifyContent="center" alignItems="flex-end">
                <Text fontWeight="bold" mb={-1} alignSelf="flex-end"> Usage:  {(userUsageBar?.customCount + " / " + userUsageBar?.customMax)}</Text>
              </Box>
            </Flex>

          </div>
        </div>
      </Box>

      <ComingSoon
        onClose={upgradeModal.onClose}
        isOpen={upgradeModal.isOpen}
      />

    </DashBoardLayout>
  );
};

export default DashboardHome;

const SocialCard = ({ onClick = () => { }, icon, title, text }) => {
  return (
    <Box
      display={"flex"}
      alignItems="center"
      backgroundColor={"white"}
      borderRadius={10}
      // h="100%"
      p="3"
      w={"100%"}
      gap={{ base: "2", md: "5" }}
      cursor="pointer"
      onClick={onClick}
    >
      <Icon boxSize={{ base: 7, md: 10 }} as={icon} color="gray3" />
      <Box>
        <Text fontWeight={"700"} fontSize={{ base: "14", md: "18" }}>
          {title}
        </Text>
        <Text
          fontWeight={"400"}
          fontSize={{ base: "12", md: "14" }}
          color={"rgba(0, 0, 0, 0.6)"}
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
};
