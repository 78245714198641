import React from "react";

const InstaComment = (props) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.8332 17.3952L13.4998 14.0618H1.83317C1.37484 14.0618 0.982476 13.8987 0.656087 13.5723C0.329698 13.2459 0.166504 12.8535 0.166504 12.3952L0.166504 2.39518C0.166504 1.93685 0.329698 1.54449 0.656087 1.2181C0.982476 0.89171 1.37484 0.728516 1.83317 0.728516L15.1665 0.728516C15.6248 0.728516 16.0172 0.89171 16.3436 1.2181C16.67 1.54449 16.8332 1.93685 16.8332 2.39518V17.3952ZM1.83317 2.39518L1.83317 12.3952H14.1873L15.1665 13.3743V2.39518L1.83317 2.39518Z"
        fill="black"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default InstaComment;
