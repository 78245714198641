import React from "react";
import { Box, Text, Image, Icon } from "@chakra-ui/react";
import images from "../../constants/images";
import { MediumSave, HorizontalDots } from "../icons";

const SocialMediumCard = ({ text,resImg,dashInfo }) => {
  return (
    <Box backgroundColor={"white"} borderRadius={10}>
      <Box display={"flex"} alignItems="center" p="2">
        <Image src={images.facebookAvatar} w="10" h="10" borderRadius={100} />
        <Box ml="3" display={"flex"}>
          <Text fontWeight={"600"} fontSize="16" color={"orbBlack"}>
          {dashInfo?.name}
          </Text>
          <Text ml="1" fontWeight={"500"} color="#5B7083" fontSize="16">
            · 4 Days Ago
          </Text>
        </Box>
      </Box>
      <Box display={"flex"} p="2" gap="4">
        <Box>
          <Text fontWeight={"400"} color="#292929" fontSize="14">
            {text}
          </Text>
        </Box>
        <Image src={resImg ? resImg : images.medium} w="40" p="2" />
      </Box>
      <Box
        mb="3"
        p="2"
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Box display={"flex"} alignItems="center" gap={"2"}>
          <Text
            fontWeight={"500"}
            fontSize="12"
            backgroundColor={"#F2F2F2"}
            p="1"
            textAlign={"center"}
            w={16}
            color="#292929"
            borderRadius={10}
          >
            Portfolio
          </Text>
          <Text fontSize="12" fontWeight={"500"} color="#757575">
            3 min read
          </Text>
          <Text> · </Text>
          <Text fontSize="12" fontWeight={"500"} color="#757575">
            Selected for you
          </Text>
        </Box>
        <Box display={"flex"} gap="1" mr="2">
          <Icon as={MediumSave} />
          <Icon as={HorizontalDots} />
        </Box>
      </Box>
    </Box>
  );
};

export default SocialMediumCard;
