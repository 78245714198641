import React from "react";

const InstaLike = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.49984 15.5632L7.2915 14.4798C5.88873 13.2159 4.729 12.1256 3.81234 11.209C2.89567 10.2923 2.1665 9.4694 1.62484 8.74023C1.08317 8.01107 0.704698 7.34093 0.489421 6.72982C0.274143 6.11871 0.166504 5.49371 0.166504 4.85482C0.166504 3.54926 0.604004 2.45898 1.479 1.58398C2.354 0.708984 3.44428 0.271484 4.74984 0.271484C5.47206 0.271484 6.15956 0.424262 6.81234 0.729818C7.46511 1.03537 8.02761 1.46593 8.49984 2.02148C8.97206 1.46593 9.53456 1.03537 10.1873 0.729818C10.8401 0.424262 11.5276 0.271484 12.2498 0.271484C13.5554 0.271484 14.6457 0.708984 15.5207 1.58398C16.3957 2.45898 16.8332 3.54926 16.8332 4.85482C16.8332 5.49371 16.7255 6.11871 16.5103 6.72982C16.295 7.34093 15.9165 8.01107 15.3748 8.74023C14.8332 9.4694 14.104 10.2923 13.1873 11.209C12.2707 12.1256 11.1109 13.2159 9.70817 14.4798L8.49984 15.5632ZM8.49984 13.3132C9.83317 12.1187 10.9304 11.0944 11.7915 10.2402C12.6526 9.38607 13.3332 8.64301 13.8332 8.01107C14.3332 7.37912 14.6804 6.81662 14.8748 6.32357C15.0693 5.83051 15.1665 5.34093 15.1665 4.85482C15.1665 4.02148 14.8887 3.32704 14.3332 2.77148C13.7776 2.21593 13.0832 1.93815 12.2498 1.93815C11.5971 1.93815 10.9929 2.12218 10.4373 2.49023C9.88178 2.85829 9.49984 3.32704 9.2915 3.89648L7.70817 3.89648C7.49984 3.32704 7.11789 2.85829 6.56234 2.49023C6.00678 2.12218 5.40261 1.93815 4.74984 1.93815C3.9165 1.93815 3.22206 2.21593 2.6665 2.77148C2.11095 3.32704 1.83317 4.02148 1.83317 4.85482C1.83317 5.34093 1.93039 5.83051 2.12484 6.32357C2.31928 6.81662 2.6665 7.37912 3.1665 8.01107C3.6665 8.64301 4.34706 9.38607 5.20817 10.2402C6.06928 11.0944 7.1665 12.1187 8.49984 13.3132Z"
        fill="#CE4848"
      />
    </svg>
  );
};

export default InstaLike;
