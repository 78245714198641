import React from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import images from "../../constants/images";

const AuthLayout = ({ children, ...props }) => {
  return (
    <Box display={"flex"} alignItems="space-between" {...props}>
      <Box
        minW={{ base: "100%", md: "55%", lg: "50%" }}
        h={{ sm:"100vh", md: "120vh", lg: "100vh" }}
        background="linear-gradient(to top,#1366b2,#0a3359)"
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        backgroundAttachment="fixed"
        margin={0}
        minH="100vh"
      >
        <Image src={images.logotitle} width={250} p="10" />
        {children}
      </Box>

      <Box display={{ base: "none", md: "block" }}>
        <Flex
          overflowX="hidden"
          overflowY="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Image
              src={images.logosvg.default}
              position="absolute"
              top="15%"
              w={{ base: "0%", md: "55%", lg: "55%" }}
              right="0"
            />
          </Box>
        </Flex>
      </Box>

    </Box>
  );
};

export default AuthLayout;
