/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Icon,
  Image,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Textarea,
  Flex
} from "@chakra-ui/react";
import {
  DashBoardLayout,
  DashboardCard,
  DropDownInput,
  InputWithTitle,
  DefaultButton,
  LoadingModal,
  IconButton,
  SocialInstagramCard,
  SocialFacebookCard,
  SocialTwitterCard,
  SocialMediumCard,
  RadioButton,
  SquirrelCard,
  FeedBackModal,
  DailyLimit
} from "../../components";
import {
  Instagram,
  Medium,
  Twitter,
  Linkedn,
  RedditColor,
  FaceBookColor,
  AutoFix,
  Pencil,
  Copy,
  Delete,
  Download,
  DownIcon,
} from "../../components/icons";
import { Languages, ToneData } from "../../constants/data";
import images from "../../constants/images";
import { ToastContainer, toast } from "react-toastify";
import { generateSocial } from "../../api/ai";
import { dashboradInfo } from "../../api/user";
import { FiEye } from 'react-icons/fi'

const CustomizedGenerations = () => {
  const [social, setSocial] = useState("landing");
  const [dropDown, setDropDown] = useState("");
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const [captionOneText, setCaptionOneText] = useState("");
  const [captionTwoText, setCaptionTwoText] = useState("");
  const [preText, setPreText] = useState("");
  const [tone, setTone] = useState("");
  const [create, setCreate] = useState(false);
  const [generate, setGenerate] = useState(false);
  const feedBackModal = useDisclosure();
  const [currentData, setCurrentData] = useState({})
  const dailyLimit = useDisclosure();

  useEffect(() => {
    document.title = 'Customized Generation | Orbina AI ';
  }, [])


  const loadingVideoModal = useDisclosure();

  const [resImg, setResImg] = useState("");
  const [dashInfo, setDashInfo] = useState({});
  const CreateText = () => {
    setCreate(true);
    loadingVideoModal.onOpen();
    setTimeout(() => openModal(), 3000);
  };

  const openModal = () => {
    loadingVideoModal.onClose();
  };

  const selectSocial = (name) => {
    setGenerate(true);
    setSocial(name);
  };

  const notify = (data) =>
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const postFoo = async () => {
    if (!dropDown) {
      notify("Please Select A Language.");
      return 0;
    }
    if (!text) {
      notify("Please Fill The Product.");
      return 0;
    }

    if (!text2) {
      notify("Please Fill The Keywords.");
      return 0;
    }

    if (!tone) {
      notify("Please Select A Tone.");
      return 0;
    }

    loadingVideoModal.onOpen();
    try {
      const obj = {
        topic: text,
        keyword: text2,
        textTone: tone?.label,
        textLanguage: dropDown?.value,
        textModel: "orbina-1",
      };

      const response = await generateSocial(social, obj);

      setCaptionOneText(response?.data?.text);
      setPreText(response?.data?.text);
      setCaptionTwoText(response?.data?.text2);
      setResImg(response?.data?.image);
      setCurrentData(response?.data)
      const response2 = await dashboradInfo();
      setDashInfo(response2?.data);

      setCreate(true);


      if (response?.response?.data?.message == "Your daily limit is reached") {
        dailyLimit.onOpen();
      }
      else {
        setTimeout(() => {
          feedBackModal.onOpen();
        }, 30000);
      }



    } catch (error) {
      console.log(error);
    } finally {
      loadingVideoModal.onClose();
    }
  };

  function downloadImage(imgUrl) {
    if (imgUrl) {
      fetch(imgUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "Orbina_Saved_Image.jpg";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  }

  return (
    <DashBoardLayout title="Ads & Social" modalType="customGen" currentData={currentData}>
      <Box
        backgroundColor={"blur"}
        borderRadius={10}
        p={{ base: "2", md: "3" }}
        mt="4"
        mb="28"
      >
        {social === "landing" && (
          <Box>
            <Text color={"white"} fontWeight="600" fontSize={"28"}>
              Ads & Social
            </Text>
            <Box
              display="grid"
              gridGap={{ base: "2", md: "4" }}
              mt={2}
              gridTemplateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr",
              }}
            >
              <DashboardCard
                icon={<Icon as={Instagram} boxSize={10} />}
                title="Instagram"
                text="Reach unique visuals and texts by specifying your special requests to Instagram."
                onClick={() => selectSocial("instagram")}
              />
              <DashboardCard
                icon={<Icon as={FaceBookColor} boxSize={10} />}
                title="Facebook"
                text="Reach unique visuals and texts by specifying your special requests to Facebook."
                onClick={() => selectSocial("facebook")}
              />
              <DashboardCard
                icon={<Icon as={Linkedn} boxSize={10} />}
                title="LinkedIn"
                text="Reach unique texts by specifying your special requests to Linkedln."
                onClick={() => selectSocial("linkedin")}
              />
              <DashboardCard
                icon={<Icon as={Twitter} boxSize={10} />}
                title="Twitter"
                text="Reach unique visuals and texts by specifying your special requests to Twitter."
                onClick={() => selectSocial("twitter")}
              />
              <DashboardCard
                icon={<Icon as={Medium} boxSize={10} />}
                title="Medium"
                text="Reach unique texts by specifying your special requests to Medium."
                onClick={() => selectSocial("medium")}
              />
              <DashboardCard
                icon={<Icon as={RedditColor} boxSize={10} />}
                title="Reddit"
                text="Reach unique visuals and texts by specifying your special requests to Reddit."
                onClick={() => selectSocial("reddit")}
              />
            </Box>
          </Box>
        )}
        {generate && (
          <Box>
            {social === "instagram" && (
              <Text color={"white"} fontWeight="600" fontSize={"28"}>
                Instagram Captions
              </Text>
            )}
            {social === "facebook" && (
              <Text color={"white"} fontWeight="600" fontSize={"28"}>
                Facebook Post
              </Text>
            )}
            {social === "twitter" && (
              <Text color={"white"} fontWeight="600" fontSize={"28"}>
                Twitter Post
              </Text>
            )}
            {social === "medium" && (
              <Text color={"white"} fontWeight="600" fontSize={"28"}>
                Medium Post
              </Text>
            )}
            {social === "linkedin" && (
              <Text color={"white"} fontWeight="600" fontSize={"28"}>
                LinkedIn Post
              </Text>
            )}
            <Text color={"#B5B2C6"} fontWeight="400" fontSize={"14"}>
              Write scroll- stopping captions that encourages people to stop,
              look and like.
            </Text>
            <Box
              display={"flex"}
              flexDirection={{ base: "column", md: "row" }}
              mt={"5"}
              px={{ lg: "5" }}
              gap={{ base: "3", md: "5", lg: "16" }}
            >
              <Box
                w={{ base: "100%", md: "45%", lg: "45" }}
                display="flex"
                flexDirection={"column"}
              >
                <Box w={{ base: "40%", md: "65%", lg: "40%" }}>
                  <DropDownInput
                    Data={Languages}
                    placeholder="Select Language"
                    onChange={(val) => setDropDown(val)}
                    borderRadius={15}
                    value={dropDown}
                  />
                </Box>
                <Box>

                  <Box>
                    <Box
                      display={"flex"}
                      justifyContent="space-between"
                      alignItems={"center"}
                      mt="3"
                    >
                      <Text fontWeight={"500"} fontSize={18} letterSpacing={"-0.04em"} color={"gray"}>
                        Product*
                      </Text>
                      <Text fontWeight={"500"} fontSize="14" color={"lightGray"}>
                        {text?.length}/{social == "linkedin" ? 400 : social == "medium" ? 800 : 200}
                      </Text>
                    </Box>

                    <Textarea
                      value={text}
                      placeholder="Your topic"
                      id="topic"
                      _placeholder={{ opacity: 0.6 }}
                      backgroundColor={"white"}
                      borderRadius={10}
                      mb="2"
                      mt="1"
                      minH={"20"}
                      maxH={null}
                      onChange={(val) => {
                        if (social == "linkedin") {
                          if ((text.length < 400) || (text.length == 400 && val?.nativeEvent?.data == null)) { setText(val.target.value) }
                        }
                        else if (social == "medium") {
                          if ((text.length < 800) || (text.length == 800 && val?.nativeEvent?.data == null)) { setText(val.target.value) }
                        }
                        else {
                          if ((text.length < 200) || (text.length == 200 && val?.nativeEvent?.data == null)) { setText(val.target.value) }
                        }

                      }
                      }
                    />
                  </Box>

                  <InputWithTitle
                    label="Keywords*"
                    placeholder="Primary keywords"
                    _placeholder={{ opacity: 0.6 }}
                    onChange={(val) => setText2(val.target.value)}
                    textCount={text2?.length}
                  />
                  <DropDownInput
                    Data={ToneData}
                    label="Tone*"
                    placeholder="Select tone"
                    onChange={(val) => setTone(val)}
                    value={tone}
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"center"}
                  gap={{ base: "5", md: "5", lg: "10" }}
                  alignContent="center"
                  mt="8"
                >
                  <DefaultButton
                    text={"Create"}
                    rightIcon={<Icon as={Pencil} />}
                    as="button"
                    color="white"
                    fontWeight="bold"
                    borderRadius={10}
                    mb="2"
                    borderColor={create && "white"}
                    borderWidth={create && 1}
                    backgroundColor={create && "transparent"}
                    bgGradient={!create && "linear-gradient(90deg, #042b66 0.01%, #2fafe7 99.99%)"}
                    width="150px"
                    fontSize={{ base: "12", lg: "14" }}
                    onClick={() => postFoo()}
                  />
                  <DefaultButton
                    text={"Make variations"}
                    borderColor={"white"}
                    borderRadius={10}
                    color="white"
                    borderWidth={!create && 1}
                    mb="2"
                    backgroundColor={"transparent"}
                    bgGradient={create && "linear-gradient(90deg, #042b66 0.01%, #2fafe7 99.99%)"}
                    boxShadow={"xl"}
                    rightIcon={<Icon as={AutoFix} />}
                    width="150px"
                    fontSize={{ base: "12", lg: "14" }}
                    onClick={() => postFoo()}
                  />
                </Box>
              </Box>
              <Box w={{ base: "100%", md: "55%", lg: "45" }}>
                {create ? (
                  <Box>
                    <Tabs isLazy>
                      <TabList
                        borderColor={"#F2F2F2"}
                        borderBottomWidth={1}
                        gap={{ base: "20", md: "40", lg: "52" }}
                      >
                        <Tab
                          _selected={{
                            borderColor: "#A1E3CB",
                          }}
                        >
                          <Icon as={Pencil} />
                          <Text
                            pl={3}
                            fontWeight={"700"}
                            color="white"
                            fontSize={"16"}
                          >

                            Edit
                          </Text>
                        </Tab>
                        {social !== "linkedin" && (
                          <Tab
                            _selected={{
                              borderColor: "#A1E3CB",
                            }}
                          >
                            <Icon as={FiEye} boxSize={5} color="white" />
                            <Text
                              pl={3}
                              fontWeight={"700"}
                              color="white"
                              fontSize={"16"}
                              cursor="pointer"
                            //onClick={() => setTab(tabs.textList)}
                            >
                              Preview
                            </Text>
                          </Tab>
                        )}
                      </TabList>
                      <TabPanels>
                        {/* initially mounted */}
                        <TabPanel>
                          <Text fontWeight={"700"} color="gray" fontSize={"18"}>
                            Caption
                          </Text>

                          <Box display={"flex"} gap="6" flexDirection="column" mt={4}>

                            <CaptionArea
                              onClick={(val) =>
                                setCaptionOneText(val.target.value)
                              }
                              textLength={captionOneText}
                              text={captionOneText}
                              setPreText={setPreText}
                            />

                            <CaptionArea
                              onClick={(val) =>
                                setCaptionTwoText(val.target.value)
                              }
                              textLength={captionTwoText}
                              text={captionTwoText}
                              setPreText={setPreText}
                            />

                          </Box>
                        </TabPanel>
                        {/* initially not mounted */}
                        <TabPanel>
                          <Box display={"flex"} flexDirection="column">
                            {social === "instagram" && (
                              <SocialInstagramCard
                                text={preText}
                                resImg={resImg}
                                dashInfo={dashInfo}
                              />
                            )}
                            {social === "facebook" && (
                              <SocialFacebookCard
                                text={preText}
                                resImg={resImg}
                                dashInfo={dashInfo}
                              />
                            )}
                            {social === "twitter" && (
                              <SocialTwitterCard
                                text={preText}
                                resImg={resImg}
                                dashInfo={dashInfo}
                              />
                            )}
                            {social === "medium" && (
                              <SocialMediumCard
                                text={preText}
                                resImg={resImg}
                                dashInfo={dashInfo}
                              />
                            )}

                            <Box
                              display={"flex"}
                              alignSelf={"flex-end"}
                              mt="2"
                              gap="3"
                            >
                              <IconButton
                                icon={
                                  <Icon
                                    as={Download}
                                    color="black"
                                    boxSize={3}
                                  />
                                }
                                onClick={() => downloadImage(resImg)}
                              />

                              {/* <IconButton
                                icon={<Icon as={Copy} boxSize={3} />}
                                onClick={() => console.log()}
                              /> */}
                            </Box>
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Box>
                ) : (
                  <SquirrelCard text="No Content Yet." />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <LoadingModal
        onClose={loadingVideoModal.onClose}
        isOpen={loadingVideoModal.isOpen}
      />

      <FeedBackModal
        onClose={feedBackModal.onClose}
        isOpen={feedBackModal.isOpen}
        modalType="customGen"
        currentData={currentData}
      />

      <DailyLimit
        onClose={dailyLimit.onClose}
        isOpen={dailyLimit.isOpen}
      />

    </DashBoardLayout>
  );
};

export default CustomizedGenerations;

const CaptionArea = ({ onClick = () => { }, textLength, text = "", setPreText, index }) => {
  const CopyText = (text) => {
    navigator?.clipboard?.writeText?.(text);
    toast.success("text successfully copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <Box display={"flex"} flexDirection="column">
      <Textarea
        // placeholder={textLength}
        value={textLength}
        backgroundColor={"white"}
        borderRadius={10}
        mb="2"
        mt="1"
        minH={"32"}
        onChange={onClick}
      />
      <Flex justify="space-between">
        <DefaultButton
          text={"Use This Text"}
          borderRadius={10}
          color="white"
          bgGradient={"linear-gradient(90deg, #042b66 0.01%, #2fafe7 99.99%)"}
          boxShadow={"lg"}
          width="130px"
          fontSize="14"
          onClick={() => setPreText(text)}
        />

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Text
            fontWeight={"400"}
            fontSize="14"
            color="lightGray"
          >
            {textLength?.length} characters
          </Text>
          <Box display="flex" alignSelf="flex-end" gap="3">
            <IconButton
              icon={<Icon as={Copy} boxSize={3} />}
              onClick={() => CopyText(text)}
            />
          </Box>
        </Box>
      </Flex>

    </Box>
  );
};
