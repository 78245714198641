import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Button, Box, Text, Image, Input, useDisclosure, Avatar, Grid, GridItem, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter,
  Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Select, Progress, Divider, useBreakpointValue
} from "@chakra-ui/react";
import {
  AuthInput,
  DashBoardLayout,
  DropDownInput,
  DefaultButton,
  RadioButton,
  LoadingModal
} from "../../components";
import {
  Settings,
} from "../../components/icons";
import images from "../../constants/images";
import { Role } from "../../constants/data";
import { getProfile, updateProfile, userUsage, userUsageMonth } from "../../api/user";
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import newSteps from './steps/profileStep';
import { stepsUpdate } from "../../api/user";
import { UserContext } from "../../context/user";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: "smooth", block: "center" },
  },
  useModalOverlay: true
};

const Autton = () => {
  const tour = useContext(ShepherdTourContext);
  const [state, dispatch] = useContext(UserContext);

  const handleStepCompletion = async (step) => {
    dispatch({ type: 'UPDATE_STEP', payload: { step, value: true } });

    try {
      const response = await stepsUpdate({ stepComplete2: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!state.stepComplete2 && !tour.isActive()) {
      tour.start();
    }

    tour.on('complete', () => {
      handleStepCompletion('stepComplete2');
    });

  }, [tour]);

  return (<> </>);
}



const brandInfo = ['Fashion', 'Home and Living', 'Hobbies', 'Electronics', 'Others']

const Profile = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const [activeIndex3, setActiveIndex3] = useState(0);
  const [activeIndex4, setActiveIndex4] = useState(0);

  const [disableButtons, setDisableButtons] = useState(false);
  const months = [
    "",
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [userInfo, setUserInfo] = useState({})
  const [userInfo2, setUserInfo2] = useState({})
  const imageInput = useRef();
  const [brandIndex, setBrandIndex] = useState(-1)
  const [render, setRender] = useState(false)
  const [previewImage, setPreviewImage] = useState(null);
  const [userUsageBar, setUserUsageBar] = useState({})

  const onSelectImage = async () => {
    imageInput.current?.click();
  };

  const onUploadImage = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      userInfo2["profilePicture"] = event.target.files[0];
      setPreviewImage(reader.result);
    };

  };

  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])
  const [data3, setData3] = useState([])
  const [data4, setData4] = useState([])

  useEffect(() => {
    document.title = 'Profile | Orbina AI ';

    const getData = async () => {
      loadingVideoModal.onOpen();
      const currentMonth = new Date().getMonth() + 1;
      setActiveIndex(currentMonth)
      setActiveIndex2(currentMonth)
      setActiveIndex3(currentMonth)
      setActiveIndex4(currentMonth)

      const response = await getProfile();
      const response2 = await userUsage();
      const response3 = await userUsageMonth({ month: currentMonth, type: "image" })
      const response4 = await userUsageMonth({ month: currentMonth, type: "text" })
      const response5 = await userUsageMonth({ month: currentMonth, type: "chat" })
      const response6 = await userUsageMonth({ month: currentMonth, type: "customgen" })

      var temp1 = []
      var temp2 = []
      var temp3 = []
      var temp4 = []

      response3?.data?.data.map(item => { temp1.push({ name: item.day, value: item.usage }) })
      response4?.data?.data.map(item => { temp2.push({ name: item.day, value: item.usage }) })
      response5?.data?.data.map(item => { temp3.push({ name: item.day, value: item.usage }) })
      response6?.data?.data.map(item => { temp4.push({ name: item.day, value: item.usage }) })

      setData1(temp1)
      setData2(temp2)
      setData3(temp3)
      setData4(temp4)

      setUserUsageBar(response2?.data)
      setUserInfo(response?.data)

      if (response?.data.hasOwnProperty("brandSector")) {
        const indexOfEl = brandInfo.indexOf(response?.data.brandSector);
        setBrandIndex(indexOfEl);
      }

      loadingVideoModal.onClose();

    }

    getData();


  }, [render])

  const loadingVideoModal = useDisclosure();


  const saveChanges = async () => {
    loadingVideoModal.onOpen();

    try {
      const response = await updateProfile(userInfo2)
    } catch (error) {
      console.log(error);
    }
    finally {
      setRender(!render)
    }

  }

  const modalWidth = useBreakpointValue({ base: '100%', md: '55%' });

  const handlePreviousMonth = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? months.length - 1 : prevIndex - 1));
  };

  const handleNextMonth = () => {
    setActiveIndex((prevIndex) => (prevIndex === months.length - 1 ? 0 : prevIndex + 1));
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setDisableButtons(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [activeIndex4,activeIndex3, activeIndex2, activeIndex]);


  const getData1 = async (index, type) => {

    if (index != 0 && index != 13) {
      if (type == "image") {
        const response3 = await userUsageMonth({ month: index, type: "image" });
        var temp1 = [];
        response3?.data?.data.map(item => {
          if (item?.usage !== 0) {
            temp1.push({ name: item?.day, value: item?.usage });
          }
        });
        setData1(temp1);
        setActiveIndex(index);
      }

      if (type == "text") {
        const response3 = await userUsageMonth({ month: index, type: "text" });
        var temp1 = [];
        response3?.data?.data.map(item => {
          if (item?.usage !== 0) {
            temp1.push({ name: item?.day, value: item?.usage });
          }
        });
        setData2(temp1);
        setActiveIndex2(index);
      }

      if (type == "chat") {
        const response3 = await userUsageMonth({ month: index, type: "chat" });
        var temp1 = [];
        response3?.data?.data.map(item => {
          if (item?.usage !== 0) {
            temp1.push({ name: item?.day, value: item?.usage });
          }
        });
        setData3(temp1);
        setActiveIndex3(index);
      }

      if (type == "customgen") {
        const response3 = await userUsageMonth({ month: index, type: "customgen" });
        var temp1 = [];
        response3?.data?.data.map(item => {
          if (item?.usage !== 0) {
            temp1.push({ name: item?.day, value: item?.usage });
          }
        });
        setData4(temp1);
        setActiveIndex4(index);
      }
    }
  };


  return (
    <DashBoardLayout title="Profile">
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <Autton />
        <Grid templateColumns="1fr 1fr" mt="9">
          <GridItem></GridItem>
          <GridItem colSpan={1} p={4} textAlign="right" >
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginRight="30%" onClick={handleOpenModal} cursor={"pointer"}>
              <Box bg="white" p={2} borderRadius="50%" mr={2} display="flex" justifyContent="center" alignItems="center">
                <Icon as={Settings} boxSize={{ base: "6", sm: "8" }} />
              </Box>
              <Box bg="transparent" color="white" fontWeight="bold" id="usage">
                Use Of Credit
              </Box>
            </Box>
          </GridItem>
        </Grid>

        <Box
          flex={1}
          display={"flex"}
          mt="4"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="center"
          gap="20"
        >

          <Box minW={"35%"} display={"flex"} flexDirection="column">
            <Text fontWeight={"500"} fontSize="20" color={"white"}>
              Personal Information
            </Text>
            <Box position={"relative"} boxSize="40" alignSelf={"center"} mt="6" id="logo-tour">
              {/* <Image src={previewImage ? previewImage : userInfo.profilePicture} /> */}
              <Avatar size='2xl' name='' src={previewImage ? previewImage : userInfo.profilePicture} style={{ minWidth: "100%", minHeight: "100%" }} />
              <Input
                ref={imageInput}
                type="file"
                accept="image/*"
                display="none"
                onChange={onUploadImage}
              />

              <Image
                src={images.edit}
                boxSize="7"
                alignSelf={"end"}
                position="absolute"
                bottom={3}
                right={3}
                cursor={"pointer"}
                onClick={onSelectImage}
              />
            </Box>

            <AuthInput
              id="name"
              label={"Name"}
              placeholder="Enter Your Name"
              value={userInfo?.name}
              onChange={e => {
                {
                  setUserInfo({ ...userInfo, name: e?.target?.value });
                  userInfo2["name"] = e?.target?.value;

                }
              }

              } />

            <AuthInput
              id="mail"
              label={"Email"}
              placeholder="Enter Your Email"
              value={userInfo?.email}
              onChange={e => {
                setUserInfo({ ...userInfo, email: e?.target?.value });
                userInfo2["email"] = e?.target?.value
              }

              } />

            <DropDownInput
              id="role"
              placeholder={userInfo?.profession ? userInfo.profession : "Select Your Role!"}
              Data={Role}
              label="Your Role*"
              onChange={e => {
                setUserInfo({ ...userInfo, profession: e.value });
                userInfo2["profession"] = e?.value
              }}

            />

            <DefaultButton
              id="change"
              text={"Save Changes"}
              as="button"
              color="white"
              fontWeight="bold"
              borderRadius={10}
              mb="20"
              mt="10"
              bgGradient="linear-gradient(90deg, #042b66 0.01%, #2fafe7 99.99%)"
              width="150px"
              alignSelf="center"
              boxShadow="lg"
              onClick={() => saveChanges()}
            />
          </Box>
          <Box id="brand">
            <Text fontWeight={"500"} fontSize="20" color={"white"}>
              Brand Information
            </Text>
            <Text fontWeight={"400"} fontSize="16" color={"gray"} mt="4" mb={"7"}>
              Filling this in will influence the descriptions and blog articles
              you generate.
            </Text>

            {brandInfo.map((item, index) =>

            (<>
              <RadioButton
                text={item}
                isCheck={brandIndex == index ? true : false}
                onClick={() => {
                  setBrandIndex(index);
                  setUserInfo({ ...userInfo, brandSector: item });
                  userInfo2["brandSector"] = item;

                }}
              />
            </>)

            )}

          </Box>
        </Box>
        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent maxW={modalWidth}>
            <ModalHeader> Use Of Credit  </ModalHeader>
            <ModalBody>
              <Text mb={4}> Here you can see the usage of Orbina AI statistics. </Text>
              <Tabs isFitted>
                <TabList>

                  <Flex align="center" justify="space-between" mb={4} ml={3}>
                    <Box  >
                      <Text fontWeight="bold" >Number Of Credit Usage</Text>
                    </Box>
                  </Flex>

                  <Tab px={2} _hover={{ color: '#042b66' }} _selected={{ color: '#042b66', fontWeight: 'bold' }}>
                    Image
                  </Tab>
                  <Tab px={2} _hover={{ color: '#042b66' }} _selected={{ color: '#042b66', fontWeight: 'bold' }}>
                    Text
                  </Tab>
                  <Tab px={2} _hover={{ color: '#042b66' }} _selected={{ color: '#042b66', fontWeight: 'bold' }}>
                    Chatbot
                  </Tab>
                  <Tab px={2} _hover={{ color: '#042b66' }} _selected={{ color: '#042b66', fontWeight: 'bold' }}>
                    Customized Gen.
                  </Tab>

                  {/* <Flex align="center" justify="space-between" mb={4}>
                    <Box>
                      <Select value={selectedOption} onChange={handleSelectChange}>
                        <option value="option1">Seçenek 1</option>
                        <option value="option2">Seçenek 2</option>
                        <option value="option3">Seçenek 3</option>
                      </Select>
                    </Box>
                  </Flex> */}

                </TabList>

                <TabPanels mt={5}>

                  <TabPanel>
                    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data1} barSize={44}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 5]} tickCount={6} />
                          <Tooltip />
                          <Bar dataKey="value" fill="#042b66" />
                        </BarChart>
                      </ResponsiveContainer>
                      <div style={{ position: 'absolute', top: '-30px', right: '10px', display: 'flex', alignItems: 'center' }}>
                        <button
                          onClick={() => getData1((activeIndex - 1), "image")}
                          style={{
                            marginRight: '10px',
                            fontWeight: 'bold',
                            fontSize: '22px',
                            padding: '4px 8px',
                            pointerEvents: disableButtons ? 'none' : 'auto',
                            opacity: disableButtons ? 0.5 : 1
                          }}
                        >
                          &lt;
                        </button>
                        <span style={{ fontWeight: 'bold', fontSize: '22px'}}>{months[activeIndex]}</span>
                        <button
                          onClick={() => getData1((activeIndex + 1), "image")}
                          style={{
                            marginLeft: '10px',
                            fontWeight: 'bold',
                            fontSize: '22px',
                            padding: '4px 8px',
                            pointerEvents: disableButtons ? 'none' : 'auto',
                            opacity: disableButtons ? 0.5 : 1
                          }}
                        >
                          &gt;
                        </button>
                      </div>
                    </div>
                  </TabPanel>


                  <TabPanel>
                    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data2} barSize={44}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 5]} tickCount={6} />
                          <Tooltip />
                          <Bar dataKey="value" fill="#042b66" />
                        </BarChart>
                      </ResponsiveContainer>
                      <div style={{ position: 'absolute', top: '-30px', right: '10px', display: 'flex', alignItems: 'center' }}>
                        <button
                          onClick={() => getData1((activeIndex2 - 1), "text")}
                          style={{
                            marginRight: '10px', fontWeight: 'bold', fontSize: '22px', padding: '4px 8px',
                            pointerEvents: disableButtons ? 'none' : 'auto', // Buton etkinlik durumuna göre pointerEvents özelliği atanır
                            opacity: disableButtons ? 0.5 : 1
                          }}
                        >
                          &lt;
                        </button>
                        <span style={{ fontWeight: 'bold', fontSize: '22px' }}>{months[activeIndex2]}</span>
                        <button
                          onClick={() => getData1((activeIndex2 + 1), "text")}
                          style={{
                            marginLeft: '10px', fontWeight: 'bold', fontSize: '22px', padding: '4px 8px',
                            pointerEvents: disableButtons ? 'none' : 'auto', // Buton etkinlik durumuna göre pointerEvents özelliği atanır
                            opacity: disableButtons ? 0.5 : 1
                          }}
                        >
                          &gt;
                        </button>
                      </div>
                    </div>
                  </TabPanel>

                 
                  <TabPanel>
                    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data3} barSize={44}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 5]} tickCount={6} />
                          <Tooltip />
                          <Bar dataKey="value" fill="#042b66" />
                        </BarChart>
                      </ResponsiveContainer>
                      <div style={{ position: 'absolute', top: '-30px', right: '10px', display: 'flex', alignItems: 'center' }}>
                        <button
                          onClick={() => getData1((activeIndex3 - 1), "chat")}
                          style={{
                            marginRight: '10px', fontWeight: 'bold', fontSize: '22px', padding: '4px 8px',
                            pointerEvents: disableButtons ? 'none' : 'auto', // Buton etkinlik durumuna göre pointerEvents özelliği atanır
                            opacity: disableButtons ? 0.5 : 1
                          }}
                        >
                          &lt;
                        </button>
                        <span style={{ fontWeight: 'bold', fontSize: '22px' }}>{months[activeIndex3]}</span>
                        <button
                          onClick={() => getData1((activeIndex3 + 1), "chat")}
                          style={{
                            marginLeft: '10px', fontWeight: 'bold', fontSize: '22px', padding: '4px 8px',
                            pointerEvents: disableButtons ? 'none' : 'auto', // Buton etkinlik durumuna göre pointerEvents özelliği atanır
                            opacity: disableButtons ? 0.5 : 1
                          }}
                        >
                          &gt;
                        </button>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data4} barSize={44}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 5]} tickCount={6} />
                          <Tooltip />
                          <Bar dataKey="value" fill="#042b66" />
                        </BarChart>
                      </ResponsiveContainer>
                      <div style={{ position: 'absolute', top: '-30px', right: '10px', display: 'flex', alignItems: 'center' }}>
                        <button
                          onClick={() => getData1((activeIndex4 - 1), "customgen")}
                          style={{
                            marginRight: '10px', fontWeight: 'bold', fontSize: '22px', padding: '4px 8px',
                            pointerEvents: disableButtons ? 'none' : 'auto', // Buton etkinlik durumuna göre pointerEvents özelliği atanır
                            opacity: disableButtons ? 0.5 : 1
                          }}
                        >
                          &lt;
                        </button>
                        <span style={{ fontWeight: 'bold', fontSize: '22px' }}>{months[activeIndex4]}</span>
                        <button
                          onClick={() => getData1((activeIndex4 + 1), "customgen")}
                          style={{
                            marginLeft: '10px', fontWeight: 'bold', fontSize: '22px', padding: '4px 8px',
                            pointerEvents: disableButtons ? 'none' : 'auto', // Buton etkinlik durumuna göre pointerEvents özelliği atanır
                            opacity: disableButtons ? 0.5 : 1
                          }}
                        >
                          &gt;
                        </button>
                      </div>
                    </div>
                  </TabPanel>

                </TabPanels>
              </Tabs>

              <div style={{ width: '100%' }}>

                <div style={{ width: '100%', height: '100%', background: 'white', marginTop: "0px", borderRadius: '8px', padding: '20px' }}>
                  <Text color={"black"} fontWeight={"600"} fontSize="24">
                    Your Remaining Trial Credit
                  </Text>
                  <Text color={"black"} fontWeight={"400"} fontSize="16" mt={0} mb={2}>
                    (All credits reset at 00:00 am.)
                  </Text>
                  <Divider mt={"3"} mb={2} color="lightGray"></Divider>

                  <Flex alignItems="flex-end" mb={5}>
                    <Box w="80%" position="relative">
                      <Text fontWeight="bold" mb={2}>Image Generation Credit Limit For Today </Text>
                      <Progress value={parseInt((userUsageBar?.imageCount / userUsageBar?.imageMax) * 100)} colorScheme='#042b66;' bg="rgba(0,0,0,0.1)" borderRadius="8px" hasStripe isAnimated />
                    </Box>
                    <Box w="20%" display="flex" justifyContent="center" alignItems="flex-end">
                      <Text fontWeight="bold" mb={-1} alignSelf="flex-end">Usage: {(userUsageBar?.imageCount + " / " + userUsageBar?.imageMax)}</Text>
                    </Box>
                  </Flex>

                  <Flex alignItems="flex-end" mb={5}>
                    <Box w="80%" position="relative">
                      <Text fontWeight="bold" mb={2}>Text & Article Credit Limit For Today </Text>
                      <Progress value={parseInt((userUsageBar?.textCount / userUsageBar?.textMax) * 100)} colorScheme='#042b66;' bg="rgba(0,0,0,0.1)" borderRadius="8px" hasStripe isAnimated />
                    </Box>
                    <Box w="20%" display="flex" justifyContent="center" alignItems="flex-end">
                      <Text fontWeight="bold" mb={-1} alignSelf="flex-end"> Usage: {(userUsageBar?.textCount + " / " + userUsageBar?.textMax)} </Text>
                    </Box>
                  </Flex>

                  <Flex alignItems="flex-end" mb={5}>
                    <Box w="80%" position="relative">
                      <Text fontWeight="bold" mb={2}>Chatbot Credit Limit For Today </Text>
                      <Progress value={parseInt((userUsageBar?.chatCount / userUsageBar?.chatMax) * 100)} colorScheme='#042b66;' bg="rgba(0,0,0,0.1)" borderRadius="8px" hasStripe isAnimated />
                    </Box>
                    <Box w="20%" display="flex" justifyContent="center" alignItems="flex-end">
                      <Text fontWeight="bold" mb={-1} alignSelf="flex-end"> Usage:  {(userUsageBar?.chatCount + " / " + userUsageBar?.chatMax)}</Text>
                    </Box>
                  </Flex>

                  <Flex alignItems="flex-end">
                    <Box w="80%" position="relative">
                      <Text fontWeight="bold" mb={2}>Customized Generation Credit Limit For Today </Text>
                      <Progress value={parseInt((userUsageBar?.customCount / userUsageBar?.customMax) * 100)} colorScheme='#042b66;' bg="rgba(0,0,0,0.1)" borderRadius="8px" hasStripe isAnimated />
                    </Box>
                    <Box w="20%" display="flex" justifyContent="center" alignItems="flex-end">
                      <Text fontWeight="bold" mb={-1} alignSelf="flex-end"> Usage:  {(userUsageBar?.customCount + " / " + userUsageBar?.customMax)}</Text>
                    </Box>
                  </Flex>

                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                Close
              </Button>

            </ModalFooter>
          </ModalContent>
        </Modal>

        <LoadingModal
          onClose={loadingVideoModal.onClose}
          isOpen={loadingVideoModal.isOpen}
        />
      </ShepherdTour>
    </DashBoardLayout>
  );
};

export default Profile;
