import React from "react";

const InstaSend = (props) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.20315 16.1094L0.666504 5.86719L17.0975 0.851046L9.20315 16.1094ZM8.88281 13.1219L13.8008 3.59878L3.5474 6.72053L5.41479 8.96102L10.0559 6.71999L7.01542 10.8814L8.88281 13.1219Z"
        fill="black"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default InstaSend;
