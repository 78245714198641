import React from "react";
import { Box } from "@chakra-ui/react";

const IconButton = ({ onClick = () => {}, icon }) => {
  return (
    <Box
      display={"flex"}
      w={"8"}
      h="8"
      alignItems={"center"}
      justifyContent="center"
      backgroundColor="white"
      borderRadius={10}
      cursor="pointer"
      onClick={onClick}
    >
      {icon}
    </Box>
  );
};

export default IconButton;
