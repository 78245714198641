import React from "react";
import { Box, Text, Image, Icon } from "@chakra-ui/react";
import images from "../../constants/images";
import { FacebookLike, FacebookComment, FacebookShare } from "../icons";

const SocialFacebookCard = ({ text, resImg, dashInfo }) => {
  return (
    <Box backgroundColor={"white"} borderRadius={10}>
      <Box display={"flex"} alignItems="center" p="2">
        <Image src={images.facebookAvatar} w="10" h="10" borderRadius={100} />
        <Box ml="3">
          <Text fontWeight={"600"} fontSize="16" color={"orbBlack"}>
            {dashInfo?.name}
          </Text>
          <Text fontWeight={"500"} fontSize="12" color={"black"}>
            Sponsored
          </Text>
        </Box>
      </Box>
      <Text mx="5" color="#292929">
        {text}
      </Text>
      <Image
        src={resImg ? resImg : images.cat}
        h="300"
        w={"100%"}
        objectFit="cover"
      />
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
        p="2"
      >
        <Box display={"flex"}>
          <Image src={images.facebookLike} h="7" />
          <Image
            src={images.facebookHeart}
            h="7"
            position={"absolute"}
            ml="5"
          />
        </Box>
        <Box display={"flex"} gap="4">
          <Text fontWeight={"400"} fontSize="16" color={"#606770"}>
            26 Comments
          </Text>
          <Text fontWeight={"400"} fontSize="16" color={"#606770"}>
            87 Shares
          </Text>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-around"
        borderTopWidth={1}
        borderColor="#F2F2F2"
        p="3"
      >
        <BottomTab icon={FacebookLike} text="Like" />
        <BottomTab icon={FacebookComment} text="Comment" />
        <BottomTab icon={FacebookShare} text="Share" />
      </Box>
    </Box>
  );
};

export default SocialFacebookCard;

const BottomTab = ({ icon, text }) => {
  return (
    <Box display={"flex"} alignItems="center" gap="1">
      <Icon as={icon} />
      <Text fontWeight={"400"} fontSize="16" color={"#606770"}>
        {text}
      </Text>
    </Box>
  );
};
