import React from "react";

const TwitterShare = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.1474 4.60231L8.39739 0.852313C8.17764 0.632563 7.82139 0.632563 7.60239 0.852313L3.85239 4.60231C3.63189 4.82206 3.63189 5.17831 3.85239 5.39731C4.07289 5.61631 4.42764 5.61781 4.64739 5.39731L7.43739 2.60731V10.2498C7.43739 10.5603 7.68939 10.8123 7.99989 10.8123C8.31039 10.8123 8.56239 10.5603 8.56239 10.2498V2.60731L11.3524 5.39731C11.4619 5.50756 11.6059 5.56231 11.7499 5.56231C11.8939 5.56231 12.0379 5.50831 12.1474 5.39731C12.3671 5.17756 12.3671 4.82206 12.1474 4.60231Z"
        fill="#5B7083"
      />
      <path
        d="M13.781 15.458H2.219C1.271 15.458 0.5 14.687 0.5 13.739V9.5C0.5 9.1895 0.752 8.9375 1.0625 8.9375C1.373 8.9375 1.625 9.1895 1.625 9.5V13.739C1.625 14.0668 1.89125 14.333 2.219 14.333H13.781C14.1088 14.333 14.375 14.0668 14.375 13.739V9.5C14.375 9.1895 14.627 8.9375 14.9375 8.9375C15.248 8.9375 15.5 9.1895 15.5 9.5V13.739C15.5 14.687 14.729 15.458 13.781 15.458Z"
        fill="#5B7083"
      />
    </svg>
  );
};

export default TwitterShare;
