import React from "react";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Image,
  Box,
  Text,
} from "@chakra-ui/react";
import images from "../../constants/images";
import Loader from '../../theme/loader';

const LoadingModal = ({ onClose = () => { }, isOpen = false }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg={"rgb(162, 162, 162, 0.79)"} />
      <ModalContent bg={"rgb(162, 162, 162, 0)"} boxShadow="none">
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
        >
          <Box display="flex" alignItems="center" justifyContent={"center"}>
            <Loader
              size={"xl"}
              color="#042b66"
              emptyColor="emptySpinner"
              boxSize="80"
              thickness="16px"
              position={"absolute"}
            />
          </Box>

        </Box>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
