import React from "react";

const BookMarkActive = (props) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 22V6C0 5.45 0.195833 4.97917 0.5875 4.5875C0.979167 4.19583 1.45 4 2 4H12C12.55 4 13.0208 4.19583 13.4125 4.5875C13.8042 4.97917 14 5.45 14 6V22L7 19L0 22ZM2 18.95L7 16.8L12 18.95V6H2V18.95ZM16 19V2H3V0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V19H16Z"
        fill="#042b66"
      />
    </svg>
  );
};

export default BookMarkActive;
