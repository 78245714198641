/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {
  DashBoardLayout,
  Message,
  LoadingModal,
  DefaultButton,
  DailyLimit
} from "../../components";
import { Send } from "../../components/icons";
import { getChat, postChat, deleteChat } from "../../api/chat";
import { getProfile } from "../../api/user";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

const ChatBotDashBoard = () => {
  const [show, setShow] = useState(false);
  const [userInfo, setUserInfo] = useState({})

  const dailyLimit = useDisclosure();



  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [chats, setChats] = useState([]);
  const loadingVideoModal = useDisclosure();
  const [render, setrender] = useState(true);
  const [userMessage, setUserMessage] = useState("");
  useEffect(() => {
    document.title = 'Chatbot | Orbina AI ';

    const getData = async () => {
      loadingVideoModal.onOpen();
      try {
        const response = await getChat();
        setChats(response?.data?.messages);
        const responsex = await getProfile();
        setUserInfo(responsex?.data)
      } catch (error) {
        console.log(error);
      } finally {
        loadingVideoModal.onClose();
      }
    };

    getData();
  }, [render]);

  const [dot3Render, setdot3Render] = useState(false);
  const postFoo = async () => {
    var newUserMessage = userMessage
    setUserMessage("");
    var temp = [...chats];
    temp.push({ role: "user", content: newUserMessage });
    setChats(temp);
    setdot3Render(true);
    try {
      const response = await postChat({ message: newUserMessage });

      if (response?.response?.data?.message == "Your daily limit is reached") {
        dailyLimit.onOpen();
      }

      else {
        var temp = [...chats];
        temp.push(
          { role: "user", content: newUserMessage },
          { role: "assistant", content: response?.data?.text }
        );
        setChats(temp);
      }


    } catch (error) {
      console.log(error);
    } finally {
      setdot3Render(false);
    }
  };

  const deleteChatx = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",

      preConfirm: async () => {
        try {
          await deleteChat();
          setUserMessage("");

          return true;
        } catch (e) {
          Swal.showValidationMessage(`Error`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "The Chat has been deleted.", "success");
        try {
          const response = await getChat();
          setChats(response?.data?.messages);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  return (
    <DashBoardLayout title="ChatBot" modalType="chat" >
      <Box
        display={"flex"}
        flexDirection="column"
        flex={1}
        backgroundColor={"blur"}
        w="100%"
        h={"100%"}
        borderRadius={10}
        py="5"
        mt="5"
      >
        {chats?.length == 0 || chats == undefined ? null : (
          <DefaultButton
            text={"New Chat"}
            borderColor={"white"}
            borderRadius={10}
            color="white"
            borderWidth={1}
            mb="1"
            backgroundColor={"transparent"}
            boxShadow={"xl"}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            alignSelf="flex-end"
            mr="7"
            onClick={() => deleteChatx()}
            width="130px"
            fontSize="14"
          />
        )}
        <Box
          h="100%"
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
          justifyContent="space-between"
        >
          {chats?.length == 0 || chats == undefined ? (
            <Box mt="5">
              <Box display={"flex"} alignItems="center" gap="4">
                <Text
                  fontWeight={"600"}
                  textAlign="center"
                  fontSize={{ base: "28", md: "40", lg: "48" }}
                  color="#E5E5E5"
                >
                  Welcome to
                </Text>
                <Box display={"flex"}>
                  <Box w="1" h="16" backgroundColor={"#042b66"} />
                  <Box
                    w="100%"
                    h="16"
                    bgGradient="linear(to-r, rgba(209, 7, 129, 0.5), transparent, transparent)"
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Text
                      fontWeight={"600"}
                      textAlign="center"
                      fontSize={{ base: "28", md: "40", lg: "48" }}
                      color="#E5E5E5"
                      ml="3"
                    >
                      Chatbot
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Text
                fontWeight={"400"}
                textAlign="center"
                fontSize={{ base: "10", md: "14", lg: "16" }}
                color="#E5E5E5"
                mt="3"
              >
                Chatbot is at your service. You can ask your questions to the
                chatbot.
              </Text>
            </Box>
          ) : (
            <Box w={"95%"}>
              {chats.map((item) => {
                return (
                  <Message
                    isMe={item.role == "user" ? true : false}
                    text={item?.content}
                    userInfo={userInfo}
                  />
                );
              })}

              {dot3Render && <Message isMe={false} dot3={true} userInfo={userInfo} />}
            </Box>
          )}

          <form onSubmit={handleSubmit(postFoo)} style={{ width: "100%" }}>
            <InputGroup size="md" w="100%" mt="3" pl="4" pr="4">
              <Input
                color={userMessage ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.45)"}
                pr="4.5rem"
                type="text"
                focusBorderColor="rgba(255, 255, 255, 0.5)"
                backgroundColor={"blur"}
                placeholder="Example : How can I create an image in Orbina"
                onChange={(e) => setUserMessage(e?.target?.value)}
                value={userMessage}
              />

              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  backgroundColor={"pink"}
                  type="submit"
                  mr="4"
                //onClick={() => postFoo()}
                >
                  <Icon as={Send} />
                </Button>
              </InputRightElement>
            </InputGroup>
          </form>
        </Box>
      </Box>
      <LoadingModal
        onClose={loadingVideoModal.onClose}
        isOpen={loadingVideoModal.isOpen}
      />

      <DailyLimit
        onClose={dailyLimit.onClose}
        isOpen={dailyLimit.isOpen}
      />
    </DashBoardLayout>
  );
};

export default ChatBotDashBoard;
