import React from "react";

const HorizontalDots = (props) => {
  return (
    <svg
      width="21"
      height="6"
      viewBox="0 0 21 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 5.25C2.40326 5.25 1.83097 5.01295 1.40901 4.59099C0.987053 4.16903 0.75 3.59674 0.75 3C0.75 2.40326 0.987053 1.83097 1.40901 1.40901C1.83097 0.987053 2.40326 0.75 3 0.75C3.59674 0.75 4.16903 0.987053 4.59099 1.40901C5.01295 1.83097 5.25 2.40326 5.25 3C5.25 3.59674 5.01295 4.16903 4.59099 4.59099C4.16903 5.01295 3.59674 5.25 3 5.25ZM10.5 5.25C9.90326 5.25 9.33097 5.01295 8.90901 4.59099C8.48705 4.16903 8.25 3.59674 8.25 3C8.25 2.40326 8.48705 1.83097 8.90901 1.40901C9.33097 0.987053 9.90326 0.75 10.5 0.75C11.0967 0.75 11.669 0.987053 12.091 1.40901C12.5129 1.83097 12.75 2.40326 12.75 3C12.75 3.59674 12.5129 4.16903 12.091 4.59099C11.669 5.01295 11.0967 5.25 10.5 5.25ZM18 5.25C17.4033 5.25 16.831 5.01295 16.409 4.59099C15.9871 4.16903 15.75 3.59674 15.75 3C15.75 2.40326 15.9871 1.83097 16.409 1.40901C16.831 0.987053 17.4033 0.75 18 0.75C18.5967 0.75 19.169 0.987053 19.591 1.40901C20.0129 1.83097 20.25 2.40326 20.25 3C20.25 3.59674 20.0129 4.16903 19.591 4.59099C19.169 5.01295 18.5967 5.25 18 5.25Z"
        fill="#191919"
      />
    </svg>
  );
};

export default HorizontalDots;
