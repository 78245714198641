/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import {
  Text,
  Box,
  Icon,
  Image,
  Divider,
  Textarea,
  useDisclosure,
  Badge,
  Button,
  Modal,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  StarIcon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
  Flex
} from "@chakra-ui/react";
// import { StarIcon as StarOutlineIcon, StarIcon as StarFilledIcon } from '@chakra-ui/icons';
import { MdZoomIn } from "react-icons/md";
import {
  DashBoardLayout,
  DefaultButton,
  LoadingModal,
  AuthInput,
  DropDownInput,
  SavedCard,
  DailyLimit
} from "../../components";
import {
  Pencil,
  AutoFix,
  Download,
  NextArrow,
  Lock,
} from "../../components/icons";
import images from "../../constants/images";
import { Languages } from "../../constants/data";
import { generateimage } from "../../api/ai";
import { postFeedbackImg } from "../../api/feedback";
import { stepsUpdate, dashboradInfo } from "../../api/user";
import { ToastContainer, toast } from 'react-toastify';
import { UserContext } from "../../context/user";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import newSteps from './steps/image';
import { useFormik } from "formik";
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: "smooth", block: "center" },
  },
  useModalOverlay: true
};

const Autton = () => {
  const tour = useContext(ShepherdTourContext);
  const [state, dispatch] = useContext(UserContext);

  const handleStepCompletion = async (step) => {
    dispatch({ type: 'UPDATE_STEP', payload: { step, value: true } });

    try {
      const response = await stepsUpdate({ stepComplete1: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!state.stepComplete1 && !tour.isActive()) {
      tour.start();
    }

    tour.on('complete', () => {
      handleStepCompletion('stepComplete1');
    });

  }, [tour]);

  return (<> </>);
}

const tabs = {
  artWork: "artWork",
  edit: "edit",
};

const ImageGenerationDashBoard = () => {
  //image text describe
  const [text, setText] = useState("");
  const [style, setStyle] = useState("Realistic V2");
  const [tags, setTags] = useState("");
  const [generate, setGenerate] = useState(false);
  const [generateEdit, setGenerateEdit] = useState(false);
  const loadingVideoModal = useDisclosure();
  const [dropDown, setDropDown] = useState([]);
  const [tab, setTab] = useState(tabs.artWork);

  const [responseData, setResponseData] = useState({});
  const [showFeedback, setShowFeedback] = useState(true)
  const [dashInfo, setDashInfo] = useState({})
  const dailyLimit = useDisclosure();

  useEffect(() => {
    document.title = 'Image Generation | Orbina AI ';
    const getData = async () => {
      const response = await dashboradInfo();
      setDashInfo(response?.data)
      if (response?.data?.countImage % 6 == 1) {
        setShowFeedback(true)
      }
      else {
        setShowFeedback(false)
      }
    }

    getData();

  }, [])



  const notify = (data) => toast.error(data, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const GenerateImage = async () => {
    if (!text) {
      return notify("Please Describe Your Desired Image.")
    }

    if (!dropDown || dropDown?.length == 0) {
      return notify("Please Select A Language.")
    }

    loadingVideoModal.onOpen();

    try {
      const obj = {
        prompt: text,
        style: style,
        language: dropDown?.label,
      };

      const response = await generateimage(obj);
      setResponseData(response?.data);

      if (response?.response?.data?.message == "Your daily limit is reached") {
        setResponseData({});
        dailyLimit.onOpen();
      }
      else {
        const responseFeedBack = await dashboradInfo();
        if (responseFeedBack?.data?.countText % 6 == 1) {
          setShowFeedback(true)
        }
        else {
          setShowFeedback(false)
        }
      }

      //setCreate(response?.data?.text.replace(/\n/g, "<br/>"))
    } catch (error) {
      console.log(error);
    } finally {
      setGenerate(true);
      loadingVideoModal.onClose();
    }
  };

  function downloadImage() {
    if (responseData) {
      fetch(responseData?.url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "Orbina_Image_Generation.jpg";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  }

  const openModal = () => {
    loadingVideoModal.onClose();
  };

  const GenerateImageEdit = () => {
    setGenerateEdit(true);
    loadingVideoModal.onOpen();
    setTimeout(() => openModal(), 3000);
  };

  const MakeVariations = () => {
    setGenerate(true);
    loadingVideoModal.onOpen();
    setTimeout(() => openModal(), 3000);
  };

  const MakeVariationsEdit = () => {
    setGenerateEdit(true);
    loadingVideoModal.onOpen();
    setTimeout(() => openModal(), 3000);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const [isOpenx, setIsOpenx] = useState(false);

  const handleOpenModalx = () => {
    setIsOpenx(true);
  };

  const handleCloseModalx = () => {
    setIsOpenx(false);
  };



  return (
    <DashBoardLayout title="Image Generation" modalType="image" currentData={responseData} >
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <Autton />
        <Box
          display={"flex"}
          flexDirection={{ base: "column", md: "column", xl: "row" }}
          mt="2"
          pl={{ base: 0, sm: 0, xl: 20 }}
          pt={2}
          justifyContent={"space-between"}
        >
          <Box w={{ base: "100%", xl: "40%" }} mr={"5"}>
            <Box mt="3" >
              <Box  >
                <Box w={{ base: "100%", xl: "49%" }} >
                  {tab === tabs.artWork && (
                    <DropDownInput
                      Data={Languages}
                      id="lang-select"
                      placeholder="Select Language"
                      onChange={(val) => setDropDown(val)}
                      borderRadius={15}
                      value={dropDown}
                    />
                  )}
                </Box>
              </Box>
              <Box display={"flex"} gap="2" mt="2">
                <DefaultButton
                  id="artwork"
                  text={"Create Artwork"}
                  borderColor={"white"}
                  borderRadius={10}
                  color={tab == tabs.artWork ? "black" : "white"}
                  mb="2"
                  borderWidth={1}
                  backgroundColor={tab == tabs.artWork ? "white" : "transparent"}
                  w="100%"
                  mt="2"
                  fontSize="13"
                  onClick={() => setTab(tabs.artWork)}
                />
                <Button
                  id="edit"
                  borderColor="white"
                  borderRadius={10}
                  color={tab === tabs.edit ? "black" : "white"}
                  mb="2"
                  borderWidth={1}
                  backgroundColor={tab === tabs.edit ? "white" : "transparent"}
                  w="100%"
                  mt="2"
                  fontSize="13"
                  // onClick={() => setTab(tabs.edit)}
                  position="relative"
                >

                  <Badge
                    colorScheme="purple"
                    position="absolute"
                    top={-3}
                    right={{ base: 0, sm: 1 }}
                    borderRadius="md"
                    p={1}
                    fontSize="11px"
                  >
                    Premium
                  </Badge>

                  Edit Image
                </Button>
              </Box>
              {tab === tabs.artWork && (


                <Box>
                  <Text fontWeight={"800"} fontSize={21} letterSpacing={"-0.04em"} color={"gray"} marginTop={"3"}>
                    Hot Topics 🔥
                  </Text>
                  <Box display={"flex"} gap="2" mt="1">
                    <button
                      style={{
                        border: "1px solid white",
                        borderRadius: "9px",
                        color: tab === tabs.edit ? "black" : "white",
                        marginBottom: "2px",
                        borderWidth: "1px",
                        backgroundColor: tab === tabs.edit ? "white" : "transparent",
                        width: "100%",
                        marginTop: "6px",
                        fontSize: "13px",
                        textAlign: "center",
                        position: "relative",
                        padding: "3px"
                      }}
                      onClick={() => setText("Advertising agencies")}
                    >
                      Advertising agencies
                    </button>

                    <button
                      style={{
                        border: "1px solid white",
                        borderRadius: "9px",
                        color: tab === tabs.edit ? "black" : "white",
                        marginBottom: "2px",
                        borderWidth: "1px",
                        backgroundColor: tab === tabs.edit ? "white" : "transparent",
                        width: "100%",
                        marginTop: "6px",
                        fontSize: "13px",
                        textAlign: "center",
                        position: "relative",
                        padding: "3px",
                      }}
                      onClick={() => setText("Crypto assets")}
                    >
                      Crypto assets
                    </button>


                    <button
                      style={{
                        border: "1px solid white",
                        borderRadius: "9px",
                        color: tab === tabs.edit ? "black" : "white",
                        marginBottom: "2px",
                        borderWidth: "1px",
                        backgroundColor: tab === tabs.edit ? "white" : "transparent",
                        width: "100%",
                        marginTop: "6px",
                        fontSize: "13px",
                        position: "relative",
                        whiteSpace: "normal",
                        textAlign: "center",
                        padding: "3px",
                      }}
                      onClick={() => setText("Cake varieties")}
                    >
                      Cake varieties
                    </button>
                  </Box>
                  <Divider mt={"3"} color="lightGray"></Divider>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    mt="3"
                  >
                    <Text fontWeight={"800"} fontSize={18} letterSpacing={"-0.04em"} color={"gray"}>
                      Describe your desired image
                    </Text>
                    <Text fontWeight={"500"} fontSize="14" color={"lightGray"}>
                      {text.length}/300
                    </Text>
                  </Box>

                  <Textarea
                    value={text}
                    placeholder="Type anything"
                    _placeholder={{ opacity: 0.6 }}
                    backgroundColor={"white"}
                    borderRadius={10}
                    mb="2"
                    mt="1"
                    minH={"20"}
                    maxH={null}
                    onChange={(val) => {
                      if ((text.length < 300) || (text.length == 300 && val?.nativeEvent?.data == null)) { setText(val.target.value) }
                    }
                    }
                  />
                </Box>
              )}
              {tab === tabs.edit && (
                <Box mt={"5"}>
                  <Text
                    fontWeight={"800"}
                    fontSize="14"
                    color="white"
                    textAlign={"center"}
                  >
                    Edit With Text
                  </Text>
                  <Text
                    fontWeight={"500"}
                    fontSize="10"
                    color="white"
                    textAlign={"center"}
                  >
                    Describe a change you would like to see in your image (300
                    chars max). These chenges will apply to the selected image.
                  </Text>

                  <Box
                    mt="10"
                    borderWidth={1}
                    borderStyle="dashed"
                    borderColor="white"
                    borderRadius={10}
                    p="10"
                    display={"flex"}
                    alignItems="center"
                    justifyContent={"center"}
                    gap="5"
                  >
                    <Icon as={Download} boxSize={8} />

                    <Box display={"flex"} flexDirection="column">
                      <Text
                        fontWeight={"800"}
                        fontSize="14"
                        color="white"
                        textAlign={"center"}
                      >
                        Upload Image
                      </Text>
                      <Text
                        fontWeight={"500"}
                        fontSize="10"
                        color="white"
                        textAlign={"center"}
                      >
                        Or,drag an image here
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Text fontWeight={"500"} fontSize="34" color={"white"} mt="8">
              Art Style
            </Text>
            <Divider mt={"1"} color="lightGray"></Divider>
            <Box display={"flex"} mt="5" id="artstyle">
              <Box
                display="grid"
                gridGap={{ base: "5", xl: "4" }}
                gridTemplateColumns={{
                  base: "1fr 1fr 1fr",
                  sm: "1fr 1fr 1fr 1fr 1fr",
                  md: "1fr 1fr 1fr 1fr 1fr",
                  xl: "1fr 1fr 1fr 1fr",
                }}
                w="100%"
              >
                <ArtStyleCard
                  image={images.threeDRender}
                  isPremium={"3D render"}
                  style={style}
                  onClick={() => setStyle("3D render")}
                />
                <ArtStyleCard
                  image={images.abstract}
                  isPremium={"Abstract"}
                  style={style}
                  onClick={() => setStyle("Abstract")}
                />
                <ArtStyleCard
                  image={images.anime}
                  isPremium={"Anime"}
                  style={style}
                  onClick={() => setStyle("Anime")}
                />
                <ArtStyleCard
                  image={images.artdeco}
                  isPremium={"Art deco"}
                  style={style}
                  onClick={() => setStyle("Art deco")}
                />
                <ArtStyleCard
                  image={images.cartoon}
                  isPremium={"Cartoon"}
                  style={style}
                  onClick={() => setStyle("Cartoon")}
                />
                <ArtStyleCard
                  image={images.realisticv2}
                  isPremium={"Realistic V2"}
                  style={style}
                  onClick={() => setStyle("Realistic V2")}
                />
                <ArtStyleCard
                  image={images.realistic}
                  isPremium={"Realistic V1"}
                  style={style}
                  onClick={() => setStyle("Realistic V1")}
                />
                <ArtStyleCard
                  image={images.illustration}
                  isPremium={"Illustration"}
                  style={style}
                  onClick={() => setStyle("Illustration")}
                />
                <ArtStyleCard
                  image={images.origami}
                  isPremium={"Origami"}
                  style={style}
                  onClick={() => setStyle("Origami")}
                />
                <ArtStyleCard
                  image={images.pixelart}
                  isPremium={"Pixel art"}
                  style={style}
                  onClick={() => setStyle("Pixel art")}
                />
                <ArtStyleCard
                  image={images.photography}
                  isPremium={"Photography"}
                  style={style}
                  onClick={() => setStyle("Photography")}
                />
                <ArtStyleCard
                  image={images.popart}
                  isPremium={"Pop art"}
                  style={style}
                  onClick={() => setStyle("Pop art")}
                />
                <ArtStyleCard
                  image={images.retro}
                  isPremium={"Retro"}
                  style={style}
                  onClick={() => setStyle("Retro")}
                />
                <ArtStyleCard
                  image={images.sketch}
                  isPremium={"Sketch"}
                  style={style}
                />
                <ArtStyleCard
                  image={images.vapowave}
                  isPremium={"Vaporwave"}
                  style={style}
                  onClick={() => setStyle("Vaporwave")}
                />
                <ArtStyleCard
                  image={images.digitalArt}
                  isPremium={"Digital Art"}
                  style={style}
                  onClick={() => setStyle("Digital Art")}
                />
              </Box>
            </Box>
          </Box>
          <Box

            display={"flex"}
            flexDirection="column"
            alignItems={"center"}
            justifyContent="center"
            mt={{ base: "5", xl: "-52" }}
          >
            {tab === tabs.artWork && (
              <Box id="make" display="grid"

                gridTemplateColumns={{
                  base: "1fr ",
                  md: "1fr ",
                  xl: "1fr ",
                }}>
                <DefaultButton
                  text={"Create"}
                  rightIcon={<Icon as={Pencil} />}
                  as="button"
                  p={4}
                  color="white"
                  fontWeight="bold"
                  borderRadius={10}
                  borderWidth={generate === true && 1}
                  mb="2"
                  bgGradient={
                    generate === false && "linear(to-r, #D10781, #7D2FAD)"
                  }
                  backgroundColor={generate === true && "transparent"}
                  width="150px"
                  fontSize="14"
                  boxShadow={generate === false && "xl"}
                  onClick={() => GenerateImage()}
                />
                <DefaultButton
                  text={"Make variations"}
                  borderColor={"white"}
                  borderRadius={10}
                  color="white"
                  borderWidth={generate === false && 1}
                  mb="2"
                  bgGradient={
                    generate === true && "linear(to-r, #D10781, #7D2FAD)"
                  }
                  backgroundColor={generate === false && "transparent"}
                  boxShadow={generate === true && "xl"}
                  rightIcon={<Icon as={AutoFix} />}
                  width="150px"
                  fontSize="14"
                  onClick={() => GenerateImage()}
                />
                <DefaultButton
                  text={"Download"}
                  borderColor={"white"}
                  borderRadius={10}
                  color="white"
                  mb="2"
                  borderWidth={generate === false && 1}
                  bgGradient={
                    generate === true && "linear(to-r, #D10781, #7D2FAD)"
                  }
                  backgroundColor={generate === false && "transparent"}
                  boxShadow={generate === true && "xl"}
                  rightIcon={<Icon as={Download} />}
                  width="150px"
                  fontSize="14"
                  onClick={() => downloadImage()}
                />
              </Box>
            )}

            {tab === tabs.edit && (
              <>
                <DefaultButton
                  text={"Create"}
                  rightIcon={<Icon as={Pencil} />}
                  as="button"
                  p={4}
                  color="white"
                  fontWeight="bold"
                  borderRadius={10}
                  borderWidth={generateEdit === true && 1}
                  mb="2"
                  bgGradient={
                    generateEdit === false && "linear(to-r, #D10781, #7D2FAD)"
                  }
                  backgroundColor={generateEdit === true && "transparent"}
                  width="150px"
                  fontSize="14"
                  boxShadow={generateEdit === false && "xl"}
                  onClick={() => GenerateImageEdit()}
                />
                <DefaultButton
                  text={"Make variations"}
                  borderColor={"white"}
                  borderRadius={10}
                  color="white"
                  borderWidth={generateEdit === false && 1}
                  mb="2"
                  bgGradient={
                    generateEdit === true && "linear(to-r, #D10781, #7D2FAD)"
                  }
                  backgroundColor={generateEdit === false && "transparent"}
                  boxShadow={generateEdit === true && "xl"}
                  rightIcon={<Icon as={AutoFix} />}
                  width="150px"
                  fontSize="14"
                  onClick={() => MakeVariationsEdit()}
                />
              </>
            )}
          </Box>

          <Box w={{ base: "100%", xl: "40%" }} ml={{ md: "0", xl: "4" }}>
            <Text fontWeight={"500"} fontSize="30" color={"white"}>
              Preview
            </Text>

            {generate ? (
              <Box
                w={{ base: "100%", md: "70%" }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  w={{ base: "100%",md: "120%" }}
                >
                  <Box w={{ base: "100%" }} mr={"5"}>
                    <SavedCard image={responseData?.url1} />
                  </Box>

                  <Box w={{ base: "100%" }} mr={"5"}>
                    <SavedCard image={responseData?.url2} />
                  </Box>
                </Box>

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  mt={3}
                  w={{ base: "100%",md: "120%" }}
                >
                  <Box w={{ base: "100%" }} mr={"5"}>
                    <SavedCard image={responseData?.url3} />
                  </Box>

                  <Box w={{ base: "100%" }} mr={"5"}>
                    <SavedCard image={responseData?.url4} />
                  </Box>
                </Box>

               


                {tab === tabs.edit && (
                  <Box
                    mt="3"
                    display={"flex"}
                    flexDirection="column"
                    alignItems={"center"}
                  >
                    <Box
                      display={"flex"}
                      gap={{ base: "2", md: 0 }}
                      justifyContent={{
                        base: "space-around",
                        md: "space-between",
                      }}
                      alignItems={"center"}
                      w="100%"
                    >
                      <DefaultButton
                        text={"Orbina Wallet"}
                        borderColor={"white"}
                        borderRadius={10}
                        color="white"
                        mb="2"
                        borderWidth={1}
                        backgroundColor={"transparent"}
                        width="140px"
                        fontSize="14"
                      />
                      <DefaultButton
                        text={"Download"}
                        borderColor={"white"}
                        borderRadius={10}
                        color="white"
                        mb="2"
                        borderWidth={1}
                        backgroundColor={"transparent"}
                        rightIcon={<Icon as={Download} />}
                        width="140px"
                        fontSize="14"
                      />
                    </Box>
                  </Box>
                )}


                <RatingForm responseData={responseData} handleOpenModalx={handleOpenModalx} />


              </Box>
            ) : (
              <Box
                display="grid"
                gridGap={{ base: 3, md: 5, xl: 8 }}
                gridTemplateColumns={{
                  base: "1fr 1fr",
                  md: "1fr 1fr 1fr 1fr",
                  xl: "1fr 1fr",
                }}
                mt="3"
                justifyContent={"center"}
                w={{ base: "100%", md: "100%", xl: "90%" }}
              >
                <Box
                  display={"flex"}
                  justifyContent="end"
                  maxW={"100%"}
                  h={{ base: 230, md: 210, xl: 210, xl: 240 }}
                >
                  <Image
                    src={images.imageDown}
                    position="absolute"
                    mt={2}
                    mr={2}
                    w={30}
                    h={30}
                    cursor="pointer"
                    _active={{
                      transform: "scale(0.92)",
                    }}
                    onClick={() => downloadImage()}
                  />

                  <Image
                    src={responseData?.url ? responseData?.url : images.tech}
                    borderRadius={10}
                    w="100%"
                    cursor="zoom-in"
                    onClick={handleOpenModal}
                  />
                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    fontSize={24}
                    color="white"
                    opacity={isOpen ? 1 : 0}
                    pointerEvents="none"
                    transition="opacity 0.3s"
                  >
                    <MdZoomIn />
                  </Box>
                  <Modal isOpen={isOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalBody p={0}>
                        <Image src={responseData?.url ? responseData?.url : images.tech} w="100%" h="100%" objectFit="contain" />
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Box>

                <PreviewCard />
                <PreviewCard />
                <PreviewCard />
              </Box>
            )}
          </Box>
        </Box>
        <LoadingModal
          onClose={loadingVideoModal.onClose}
          isOpen={loadingVideoModal.isOpen}
        />

        <DailyLimit
          onClose={dailyLimit.onClose}
          isOpen={dailyLimit.isOpen}
        />


      </ShepherdTour>


      <Modal isOpen={isOpenx} onClose={handleCloseModalx} size="xl" motionPreset="scale">
        <ModalOverlay />
        <ModalContent
          boxShadow="none"
          backgroundPosition="center"
          color="whiteAlpha.200"
          backgroundSize="cover"
        >
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              textAlign="center"
              fontSize="2xl"
              fontWeight="bold"
              mb={4}
              mt={6}
              pt={6}
              sx={{
                color: "black",
                fontFamily: "Montserrat, sans-serif",
                fontStyle: "italic",
                textTransform: "uppercase",
              }}
            >
              Thank You for your feedback ♥
            </Text>
          </ModalBody>

          <ModalFooter>
            <DefaultButton
              text={"Close"}
              //rightIcon={<Icon as={Pencil} />}
              as="button"
              color="white"
              fontWeight="bold"
              borderRadius={10}
              mb="1"
              borderWidth={1}
              borderColor={"white"}
              backgroundColor={"transparent"}
              bgGradient={"linear(to-r, #D10781, #7D2FAD)"}
              boxShadow={"xl"}
              width="150px"
              alignSelf="center"
              onClick={handleCloseModalx}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

    </DashBoardLayout>
  );
};

export default ImageGenerationDashBoard;

const PreviewCard = () => {
  return (
    <Box
      maxW={"100%"}
      h={{ base: 230, md: 210, xl: 210, xl: 240 }}
      borderRadius={10}
      bgGradient="linear(to-b, #999999, rgba(185, 185, 185, 0.5), rgba(217, 217, 217, 0.6) )"
      alignItems={"center"}
      justifyContent="center"
      display={"flex"}
      flexDirection="column"
    >
      <Icon as={Lock} boxSize={12} alignSelf={"center"} />
      <Text>Premium</Text>
    </Box>
  );
};

// const ArtStyleCard = ({ isPremium = false }) => {
//   return (
//     <Box
//       display={"flex"}
//       position="relative"
//       mr={{ xl: "3" }}
//       w={"100%"}
//       _hover={{ borderWidth: 1, borderRadius: 11, borderColor: "white" }}
//     >
//       <Image src={images.artStyle} w="100%" />
//       {isPremium && (
//         <Image
//           src={images.artStylePremium}
//           w="100%"
//           position={"absolute"}
//           alignSelf="end"
//         />
//       )}
//     </Box>
//   );
// };

const ArtStyleCard = ({
  onClick = () => { },
  image,
  isPremium = "",
  style = "",
}) => {
  return (
    <Box
      display={"flex"}
      position="relative"
      boxSize="100"
      borderWidth={isPremium === style && 1}
      borderRadius={10}
      borderColor="white"
      boxShadow={
        isPremium === style
          ? " rgba(0, 0, 0, 0.9) 0px 2px 40px"
          : "none"
      }
      _hover={{
        borderWidth: 1,
        boxShadow: " rgba(0, 0, 0, 0.9) 0px 2px 40px",
      }}

      onClick={onClick}
      backgroundImage={image}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      {isPremium && (
        <Box
          w="100%"
          position="absolute"
          alignSelf="end"
          borderBottomRadius={10}
          backgroundColor="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
        >
          <Text fontWeight="600" fontSize="12" color="black">
            {isPremium}
          </Text>
        </Box>
      )}
    </Box>
  );
};

const RatingForm = ({ responseData, handleOpenModalx }) => {

  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);

  const [feedBackSend, setFeedBackSend] = useState(false)


  const formik = useFormik({
    initialValues: {
      description: ''
    },
    onSubmit: async (values) => {
      var obj = {
        like: like,
        imageId: responseData?._id,
        comment: values?.description,
      }

      const response = await postFeedbackImg(obj)

      if (response.status == 200 || response.status == 201) {
        setFeedBackSend(true);
        handleOpenModalx();
      }
    }

  });

  useEffect(() => {
    setDislike(false);
    setLike(false);
    setFeedBackSend(false)
    formik.resetForm();
  }, [responseData])



  const handleLikeClick = () => {
    setLike(!like);
    setDislike(false);
  };

  const handleDislikeClick = () => {
    setLike(false);
    setDislike(!dislike);
  };



  return (
    <>
      {
        feedBackSend ?
          <>
            < Grid placeItems="center" mt={6} >
              <DefaultButton
                text={"Your Feedback Has Sent."}
                color="white"
                fontWeight="bold"
                borderRadius={10}
                mb="1"
                borderWidth={1}
                borderColor={"white"}
                backgroundColor={"transparent"}
                bgGradient={"linear(to-r, #D10781, #7D2FAD)"}
                boxShadow={"xl"}
                width="200px"
                alignSelf="center"
                display="flex"
                alignItems="center"
              />
            </Grid >
          </>
          :
          <>
            <Box maxWidth="400px">
              <Text fontWeight={"500"} fontSize="30" color={"white"} mt={2} mb={1}>
                Feedback
              </Text>

              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                <Box
                  align="center"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  background={like ? '#349f42' : 'transparent'}
                  border="1px solid white"
                  borderRadius="10px"
                  minH="40px"
                  pad="medium"
                  onClick={handleLikeClick}
                  cursor="pointer"
                >
                  <button >
                    <Flex align="center">
                      <FaThumbsUp color="white" />
                      <p style={{ color: 'white', marginLeft: '5px' }}>Like</p>
                    </Flex>
                  </button>

                </Box>
                <Box
                  align="center"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  background={dislike ? '#C40233' : 'transparent'}
                  border="1px solid white"
                  borderRadius="10px"
                  minH="40px"
                  pad="medium"
                  onClick={handleDislikeClick}
                  cursor="pointer"

                >
                  <button >
                    <Flex align="center">
                      <FaThumbsDown color="white" />
                      <p style={{ color: 'white', marginLeft: '5px' }}>Dislike</p>
                    </Flex>
                  </button>


                </Box>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <FormControl isInvalid={formik.errors.description} mb={4} mt={2}>
                  <Box display={"flex"} gap="2" mt="4">
                    <Text fontWeight={"800"} fontSize={18} letterSpacing={"-0.04em"} color={"gray"}>
                      Your Comments
                    </Text>
                    <Text fontWeight={"500"} fontSize="14" color={"lightGray"} pt={0.5}>(Optional)</Text>
                  </Box>


                  <Textarea
                    id="description"
                    placeholder="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    _placeholder={{ opacity: 0.6 }}
                    backgroundColor="white"
                    borderRadius={10}
                    mb="2"
                    mt="1"
                    minH={16}
                    maxH={null}
                  />
                  <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                </FormControl>

                <Grid placeItems="center">
                  <DefaultButton
                    text={"Submit"}
                    as="button"
                    type="submit"
                    rightIcon={<Icon as={Pencil} />}
                    isLoading={formik.isSubmitting}
                    color="white"
                    fontWeight="bold"
                    borderRadius={10}
                    mb="1"
                    borderWidth={1}
                    borderColor={"white"}
                    backgroundColor={"transparent"}
                    bgGradient={"linear(to-r, #D10781, #7D2FAD)"}
                    boxShadow={"xl"}
                    width="150px"
                    alignSelf="center"
                    display="flex"
                    alignItems="center"
                  />
                </Grid>

              </form>
            </Box>
          </>
      }
    </>
  );
};
