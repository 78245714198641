import React from "react";

const FacebookShare = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.6181 7.65721C19.8611 7.96974 20 8.36042 20 8.75109C20 9.18083 19.8611 9.53244 19.6181 9.84498L13.5069 17.0334C13.2292 17.3459 12.8819 17.5022 12.5 17.5022C12.1181 17.5022 11.8056 17.385 11.5278 17.0724C11.25 16.7599 11.1111 16.4083 11.1111 15.9395V12.5016C9.09722 12.5406 7.67361 12.6578 6.80556 12.8922C5.69444 13.2048 4.96528 13.7908 4.65278 14.6112C4.30556 15.4707 4.375 16.7208 4.82639 18.4007C4.89583 18.7914 4.86111 19.1039 4.6875 19.4165C4.51389 19.729 4.27083 19.8853 3.95833 19.9634C3.64583 20.0416 3.36806 20.0025 3.09028 19.7681C2.25694 19.1039 1.59722 18.3226 1.11111 17.385C0.347222 16.0567 0 14.5721 0 12.9313C0 10.8217 0.520833 9.18083 1.59722 7.93068C2.5 6.87586 3.88889 6.09451 5.69444 5.6257C7.08333 5.23503 8.88889 5.03969 11.1111 5.00062V1.5627C11.1111 1.13295 11.25 0.781348 11.5278 0.468809C11.8056 0.15627 12.1181 0 12.5 0C12.8819 0 13.2292 0.15627 13.5069 0.468809L19.6181 7.65721ZM18.8194 8.9855C18.8542 8.90736 18.8889 8.82923 18.8889 8.75109C18.8889 8.67296 18.8542 8.59482 18.8194 8.51669L12.7083 1.32829C12.6042 1.25016 12.5 1.25016 12.3958 1.28922C12.2569 1.36736 12.2222 1.44549 12.2222 1.5627V6.25078C9.93056 6.25078 8.15972 6.36798 6.90972 6.60239C5.10417 6.91493 3.71528 7.54 2.77778 8.43855C1.66667 9.49337 1.11111 10.9779 1.11111 12.9313C1.11111 14.2596 1.38889 15.4707 1.97917 16.5255C2.39583 17.385 2.98611 18.1273 3.75 18.7523C3.19444 16.4864 3.19444 14.8065 3.81944 13.6345C4.375 12.6188 5.41667 11.9546 6.97917 11.603C8.09028 11.3686 9.82639 11.2514 12.2222 11.2514V15.9395C12.2222 16.0958 12.2569 16.1739 12.3958 16.213C12.5 16.2911 12.6042 16.252 12.7083 16.1739L18.8194 8.9855Z"
        fill="#606770"
      />
    </svg>
  );
};

export default FacebookShare;
