/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Divider, Image, Icon, useBoolean, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import images from "../constants/images";
import {
  Home,
  HomeActive,
  Photo,
  PhotoActive,
  Difference,
  DifferenceActive,
  BookMark,
  Description,
  DescriptionActive,
  Quiz,
  Settings,
  CustomizedGenerations,
  ChatBot,
  ChatbotActive,
  BookMarkActive,
  CustomizedGenerationsActive,
} from "./icons";
import { routes } from "../constants/routes";
import { NavItem, MenuButton } from ".";

const SideBar = ({ onHoverChange }) => {
  const location = useLocation();
  const [hover, setHover] = useBoolean();

  let hoverTimeout;

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      onHoverChange(true);
      setHover.on();
    }, 50);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      onHoverChange(false);
      setHover.off();
    }, 50);
  };

  return (
    <>

      <Box
        w={{ base: "12%", lg: "8%" }}
        borderRadius={10}
        backgroundColor="white"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        display="flex"
        whiteSpace="nowrap"
        overflow="visible"
        flexDirection="column"
        alignItems="center"
        position="fixed"
        top="2"
        zIndex="3"
      >

        <Box
          w={hover ? "300px" : "100%"}
          minH="98vh"
          left={0}
          position="absolute"
          backgroundColor="white"
          transition="width 0.40s ease-in-out"
          _hover={{
            transition: "width 0.3s ease-in",
          }}
          borderRadius={10}
        >
          <Image
            src={hover ? images.logoBlack : images.logo}
            w={"70%"}
            mt={6}
            mx="auto"
          />
          <Box
            display="flex"
            flexDirection={"column"}
            px={hover && "3"}
            w={"100%"}
            mt={8}
          >
            {hover && (
              <Text color="#666666" fontWeight={"600"} fontSize="16">
                MAIN MENU
              </Text>
            )}
            <NavItem
              flex={hover ? "0 1 auto" : "none"}
              to={routes.dashboardHome.path}
              isActive={location.pathname === routes.dashboardHome.path}
              active={<Icon as={HomeActive} boxSize={"6"} />}
              inactive={<Icon as={Home} boxSize={"6"} />}
              isHover={hover}
              text="Home"
            />
            <NavItem
              flex={hover ? "0 1 auto" : "none"}
              to={routes.imageGenerationDashBoard.path}
              isActive={
                location.pathname === routes.imageGenerationDashBoard.path
              }
              active={<Icon as={PhotoActive} boxSize={"6"} />}
              inactive={<Icon as={Photo} color="black" boxSize={"6"} />}
              isHover={hover}
              text="Image Generation"
            />
            <NavItem
              flex={hover ? "0 1 auto" : "none"}
              to={routes.textArticleDashBoard.path}
              isActive={location.pathname === routes.textArticleDashBoard.path}
              active={<Icon as={DifferenceActive} boxSize={"6"} />}
              inactive={<Icon as={Difference} color="black" boxSize={"6"} />}
              isHover={hover}
              text="Text & Article"
            />
            <NavItem
              flex={hover ? "0 1 auto" : "none"}
              to={routes.customizedGenerations.path}
              isActive={location.pathname === routes.customizedGenerations.path}
              active={<Icon as={CustomizedGenerationsActive} boxSize={"6"} />}
              inactive={
                <Icon as={CustomizedGenerations} color="black" boxSize={"6"} />
              }
              isHover={hover}
              text="Customized Generations"
            />
            <NavItem
              flex={hover ? "0 1 auto" : "none"}
              to={routes.saved.path}
              isActive={location.pathname === routes.saved.path}
              active={<Icon as={BookMarkActive} boxSize={"6"} />}
              inactive={<Icon as={BookMark} boxSize={"6"} />}
              isHover={hover}
              text="Saved"
            />
            <NavItem
              flex={hover ? "0 1 auto" : "none"}
              to={routes.templatesDashBoard.path}
              isActive={location.pathname === routes.templatesDashBoard.path}
              active={<Icon as={DescriptionActive} boxSize={"6"} />}
              inactive={<Icon as={Description} color="black" boxSize={"6"} />}
              isHover={hover}
              text="Templates"
            />
            <NavItem
              flex={hover ? "0 1 auto" : "none"}
              to={routes.chatBotDashboard.path}
              isActive={location.pathname === routes.chatBotDashboard.path}
              active={<Icon as={ChatbotActive} color="black" boxSize={"6"} />}
              inactive={<Icon as={ChatBot} color="black" boxSize={"6"} />}
              isHover={hover}
              text="Chatbot"
            />
            {hover && (
              <Divider color={"#E0DFF0"} w="90%" alignSelf={"center"} my="4" />
            )}
            {hover ? (
              <Text color="#666666" fontWeight={"600"} fontSize="16" mt="4">
                PREFERENCES
              </Text>
            ) : (
              <Divider color={"black"} w="39px" alignSelf={"center"} my="5" />
            )}
            <NavItem
              flex={hover ? "0 1 auto" : "none"}

              to={routes.profile.path}
              isActive={location.pathname === routes.profile.path}
              active={<Icon as={Quiz} boxSize={"6"} />}
              inactive={<Icon as={Quiz} boxSize={"6"} />}
              isHover={hover}
              text="Settings"
            />
            {/* <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              mt={1}
            >
              <Box
                flex="1"
                justifyContent={hover ? "flex-start" : "center"}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"row"}
                gap={hover ? "1" : "0"}
                pl={hover ? "1" : "0"}
              >
                <MenuButton icon={Settings} iconSize={6} activeIcon={Settings} />
                <Text color={"black"} fontWeight={"500"} fontSize={18}>
                  {hover ? "Help" : ""}
                </Text>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SideBar;
